import { message } from 'antd/lib/message';

import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppsNStagesActionsTypes } from '../actions/apps-stages-actions';

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL1 = process.env.REACT_APP_API_URL1;
// const config = require('../../../ltts-core/scr/config');
const Axios = require('axios');

// const { backendAPI } = config;

async function createAppsNStages(data: any) {
    const response = await Axios.post(`${API_URL}/api/project_app/create`, data);
    return response.data;
}

function* createStages(data: any): any {
    try {
        const projectApps = yield call(createAppsNStages, data.payload.nodeData);
        yield put({ type: 'CREATE_STAGES_SUCCESS', projectApps });
    } catch (e) {
        yield put({ type: 'CREATE_STAGES_FAILED' });
    }
}

async function getAppsData(data: any) {
    const response = await Axios.get(`${API_URL}/api/apps/retrieve`, data.payload);
    return response.data;
}

function* getApps(payload: {}) {
    try {
        const apps = yield call(getAppsData, payload);
        yield put({ type: 'GET_APPS_SUCCESS', apps });
    } catch (e) {
        yield put({ type: 'GET_APPS_FAILED', message: e });
    }
}

async function getUsersData() {
    const response = await Axios.get(`${API_URL}/api/users`);
    return response.data;
}

// async function getAdminsData(id) {
//     const response = await Axios.get(`${API_URL}/api/projects/${id}/admin`);
//     return response.data;
// }

function* fetchUsers() {
    try {
        const users = yield call(getUsersData);
        yield put({ type: 'GET_USERS_SUCCESS', users });
    } catch (e) {
        yield put({ type: 'GET_USERS_FAILED', message: e });
    }
}

async function getAdminsData(id) {
    const response = await Axios.get(`${API_URL}/api/projects/${id}/admin`);
    return response.data;
}

function* fetchAdmins(data) {
    try {
        const admins = yield call(getAdminsData, data.payload);
        yield put({ type: 'GET_ADMINS_SUCCESS', admins: admins || [] });
    } catch (e) {
        yield put({ type: 'GET_ADMINS_FAILED', message: e });
    }
}

async function sendAppDataToMapUsers(data) {
    const response = await Axios.post(`${API_URL}/api/project_app/user`, data.payload);
    return response.data;
}

function* mapUsersToApp(payload) {
    try {
        const response = yield call(sendAppDataToMapUsers, payload);
        yield put({ type: 'MAPPING_USERSTOAPP_SUCESS', response });
    } catch (e) {
        yield put({ type: 'MAPPING_USERSTOAPP_FAILED', message: e });
    }
}

async function fetchAppAssignedApps() {
    const response = await Axios.get(`${API_URL}/api/project_app/assigned_app`);
    return response.data;
}

function* fetchProjectApps() {
    try {
        const response = yield call(fetchAppAssignedApps);
        yield put({ type: 'FETCH_PROJECT_APPS_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'FETCH_PROJECT_APPS_FAILED', message: e });
    }
}

async function fetchAnnotatorValues(data: any) {
    const response = await Axios.post(`${API_URL}/api/timetaken/annotator`, data.payload);
    return response.data;
}

function* fetchAnnotatorTime(data: any) {
    try {
        const response = yield call(fetchAnnotatorValues, data);
        yield put({ type: 'FETCH_ANNOTATOR_TIME_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'FETCH_ANNOTATOR_TIME__FAILED', message: e });
    }
}
async function SendAnnotatorstimeValues(data: any) {
    const response = await Axios.post(`${API_URL}/api/timetaken/user/session`, data.payload);
    return response.data;
}
function* SendAnnotatorTimes(data: any) {
    try {
        const response = yield call(SendAnnotatorstimeValues, data);
        yield put({ type: 'SEND_ANNOTATOR_TIME_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'SEND_ANNOTATOR_TIME__FAILED', message: e });
    }
}
async function SendUseractivityTime(data: any) {
    const response = await Axios.post(`${API_URL}/api/timetaken/user/activity/bin`, data.payload);
    return response.data;
}
function* SendUseractivity(data: any) {
    try {
        const response = yield call(SendUseractivityTime, data);
        yield put({ type: 'USER_ACTIVITY_TIME_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'USER_ACTIVITY_TIME__FAILED', message: e });
    }
}

async function fetchJobIdTaskId(data) {
    let datas = {
        app_id: data.payload.app_id,
        project_app_id: data.payload.project_app_id,
    }
    let response2;
    const response = await Axios.post(`${API_URL}/api/wfmapping/users`, datas);
    if(response.data?.message){

    }else{
        if(data.payload.userRole?.id){
            let nAgt = navigator.userAgent;
            let browserName  = navigator.appName;
            let verOffset;
            // In Opera, the true version is after "OPR" or after "Version"
            if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
             browserName = "Opera";
             if ((verOffset=nAgt.indexOf("Version"))!=-1);
            }
            // In MS Edge, the true version is after "Edg" in userAgent
            else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
             browserName = "Microsoft Edge";
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
             browserName = "Microsoft Internet Explorer";
            }
            // In Chrome, the true version is after "Chrome";
            else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
             browserName = "Chrome";
            }
            // In Safari, the true version is after "Safari" or after "Version";
            else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
             browserName = "Safari";
             if ((verOffset=nAgt.indexOf("Version"))!=-1);
            }
            // In Firefox, the true version is after "Firefox";
            else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
             browserName = "Firefox";
            }
        let data2 ={
         project_id: response.data?.project_id,
         user_id: data.payload.userRole?.id,
         workflow_id: response.data?.workflow_id,
         start_time: data.payload.start_time,
         end_time: "00:00:00",
         browser_type: browserName,
        //  session_id: "123",
         popup_flag: 'False',
        }
        response2 =  await Axios.post(`${API_URL}/api/timetaken/user/session`, data2);
    }
    }
    if(response2){
    if(response2.data?.Status === 'True'){
        sessionStorage.removeItem('isopen_status');
        if (sessionStorage.getItem("isopen_status") === null) {
            sessionStorage.setItem('isopen_status', 'true');
            localStorage.setItem('workflow_details', JSON.stringify(response.data));
          };
        return response.data;
    }else{
        let data ={
            message: 'Already apps are opened in different tab so, session terminated'
           }
           return data;
    }
}else{
    return response.data;
}
}

function* fetchWorkflowMapping(payload) {
    try {
        const response = yield call(fetchJobIdTaskId, payload);
        yield put({ type: 'FETCH_WORKFLOW_MAPPING_SUCCESS', response, payload });
    } catch (e) {
        yield put({ type: 'FETCH_WORKFLOW_MAPPING_FAILED', message: e });
        // yield put(AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING_FAILED, notification.info({ message: `No Jobs/Tasks are available` }));
    }
}

async function fetchWorkflowId(workflowId: any) {
    const response = await Axios.get(`${API_URL}/api/wfmapping/wf_chain?workflow_id=${workflowId}`, workflowId.payload);
    return response.data;
}

function* dispatchPreviousStage(id: any) {
    try {
        const response = yield call(fetchWorkflowId, id.workflowId);
        yield put({ type: 'FETCH_PREVIOUS_STAGES_SUCCESS', response, id });
    } catch (e) {
        yield put({ type: 'FETCH_PREVIOUS_STAGES_FAILED', message: e });
    }
}

async function fetchTicketReportId(id: any) {
    const response = await Axios.get(`${API_URL}/api/reports/filter/ticket?ticket=${id.ticketId}`);
    return response.data;
}

function* fetchTicketReports(ticketId: any) {
    try {
        const response = yield call(fetchTicketReportId, ticketId);
        yield put({ type: 'FETCH_TICKET_REPORTS_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'FETCH_TICKET_REPORTS_FAILED', message: e });
    }
}

async function createRejectStage(data: any) {
    const response = await Axios.post(`${API_URL}/api/wfmapping/rejected`, data.data);
    return response.data;
}

function* dispatchRejectStages(data: any) {
    try {
        const response = yield call(createRejectStage, data);
        yield put({ type: 'CREATE_REJECT_STAGES_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'CREATE_REJECT_STAGES_FAILED', message: e });
    }
}

async function updateProjectApp(data) {
    const response = await Axios.patch(`${API_URL}/api/project_app/${data.payload.id}/update`, {
        app_alias_name: data.payload.app_alias_name,
        app_config: data.payload.app_config,
    });
    return response.data;
}

function* updateProjectAppData(payload) {
    try {
        const response = yield call(updateProjectApp, payload);
        yield put({ type: 'UPDATE_PROJECTAPP_DATA_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'UPDATE_PROJECTAPP_DATA_FAILED', message: e });
    }
}

async function saveWorkflowData(data) {
    const response = await Axios.patch(`${API_URL}/api/wfmapping/status`, data.payload);
    return response.data;
}

function* saveWorkflowId(payload) {
    try {
        const response = yield call(saveWorkflowData, payload);
        yield put({ type: 'SAVE_WORKFLOW_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'SAVE_WORKFLOW_FAILED', message: e });
    }
}
async function saveAdminData(data) {
    const response = await Axios.post(`${API_URL}/api/projectmapping/user`, data);
    return response.data;
}

function* saveAdminUsers(action) {
    const { projectAdmin, redirectPage } = action.payload;
    try {
        const response = yield call(saveAdminData, projectAdmin);
        redirectPage.history.push('/projects');
        yield put({ type: 'SAVE_ADMINS_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'SAVE_ADMINS_FAILED', message: e });
    }
}

async function fetchAssignedUsers(payload) {
    const promiseData = await Axios.get(`${API_URL}/api/project_app/${payload.id}/assigned_user`);
    return promiseData.data;
}

function* fetchAssignedUsersProjectApps(payload) {
    try {
        const usersAssingedApps = yield call(fetchAssignedUsers, payload);
        yield put({ type: 'PROJECT_APPS_USER_ASSIGNED_SUCCESS', usersAssingedApps });
    } catch (e) {
        yield put({ type: 'PROJECT_APPS_USER_ASSIGNED_FAILED', message: e });
    }
}

async function fetchAssignedProjects({ projectId }) {
    const response = await Axios.get(`${API_URL}/api/projects/${projectId}/no_of_stages`);
    return response.data;
}
function* fetchAssignedProjectApps(payload) {
    try {
        const response = yield call(fetchAssignedProjects, payload);
        yield put({ type: 'FETCH_ASSIGNED_PROJECT_APPS_SUCCESS', response });
    } catch (e) {
        yield put({ type: 'FETCH_ASSIGNED_PROJECT_APPS_FAILED', message: e });
    }
}


export function* appsNStagesWatcher() {
    yield all([
        takeEvery(AppsNStagesActionsTypes.CREATE_STAGES, createStages),
        takeEvery(AppsNStagesActionsTypes.GET_APPS_REQUESTED, getApps),
        takeEvery(AppsNStagesActionsTypes.GET_USERS_REQUESTED, fetchUsers),
        takeEvery(AppsNStagesActionsTypes.GET_ADMINS_REQUESTED, fetchAdmins),
        takeEvery(AppsNStagesActionsTypes.MAP_USERS_TO_APP, mapUsersToApp),
        takeEvery(AppsNStagesActionsTypes.FETCH_PROJECT_APPS, fetchProjectApps),
        takeLatest(AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING, fetchWorkflowMapping),
        takeEvery(AppsNStagesActionsTypes.UPDATE_PROJECTAPP_DATA, updateProjectAppData),
        takeEvery(AppsNStagesActionsTypes.SAVE_WORKFLOW, saveWorkflowId),
        takeEvery(AppsNStagesActionsTypes.SAVE_ADMINS, saveAdminUsers),
        takeEvery(AppsNStagesActionsTypes.FETCH_ASSIGNED_PROJECT_APPS, fetchAssignedProjectApps),
        takeEvery(AppsNStagesActionsTypes.PROJECT_APPS_USER_ASSIGNED, fetchAssignedUsersProjectApps),
        takeEvery(AppsNStagesActionsTypes.FETCH_PREVIOUS_STAGES, dispatchPreviousStage),
        takeEvery(AppsNStagesActionsTypes.CREATE_REJECT_STAGES, dispatchRejectStages),
        takeEvery(AppsNStagesActionsTypes.FETCH_ANNOTATOR_TIME, fetchAnnotatorTime),
        takeEvery(AppsNStagesActionsTypes.FETCH_TICKET_REPORTS, fetchTicketReports),
        takeEvery(AppsNStagesActionsTypes.SEND_ANNOTATOR_TIME, SendAnnotatorTimes),
        takeEvery(AppsNStagesActionsTypes.USER_ACTIVITY_TIME, SendUseractivity),
    ]);
}
