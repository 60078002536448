// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Tabs, Select, Button, Modal, Tooltip, Table } from 'antd';
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReportDownloadIcon } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchappStageReport,
    fetchviewTask,
    activeIframeCustomer,
    fetchProjectStagesCustomer,
    resetAnnotationInfo,
} from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';
import ReactExport from 'react-export-excel';
import { getProjectsName } from 'actions/reports-action';
import { activateIframe } from 'actions/apps-stages-actions';

const { Text } = Typography;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function CustomerReviewComponent(): JSX.Element {
    let projectsName = useSelector((state: CombinedState) => state.reports.projectsName);

    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [fetchReport, setFetchReport] = useState([]);

    const loader = useSelector((state: CombinedState) => state.reports.fetching);

    const { customerStages } = useSelector((state: CombinedState) => state.reports);

    const { isIframeOpen, viewTaskDetails } = useSelector((state: CombinedState) => state.reports);

    let appStageDetails = useSelector((state: CombinedState) => state.reports.appStageDetails);
    useEffect(() => {
        dispatch(resetAnnotationInfo());
        dispatch(getProjectsName());
    }, []);

    const reportHandler = (projectId: any) => {
        dispatch(fetchProjectStagesCustomer(projectId));
    };
    const projectHandler = (id: any) => {
        setFetchReport(id);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [state, setState] = useState(viewTaskDetails);

    useEffect(() => {
        setState(viewTaskDetails);
    }, [viewTaskDetails]);

    const openIFrame = (workFlowId: any) => {
        dispatch(fetchviewTask(workFlowId));
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (viewTaskDetails?.token !== undefined && isIframeOpen) {
            let iframe = document.createElement('iframe');
            let wrapper = document.getElementById('previewCommentWrapper11');
            // code added by raju for bug fix for opening different task
            const workflow_id_job = viewTaskDetails?.workflow_id;
            const token = viewTaskDetails?.token;
            const app_config = viewTaskDetails?.app_config;
            const rotation = viewTaskDetails?.rotation;
            const translation = viewTaskDetails?.translation;
            let annotationDetails = {
                annot: app_config?.levels?.shapes,
                pixel: app_config?.levels?.pixel,
                token: token,
                cropping: app_config?.levels?.cropping,
                rotation: [rotation],
                translation: [translation],
            };
            // const task_id = appStageDetails[0]?.datameta.task_id; // commented by raju due to its always taking first value
            // const job_id = appStageDetails[0]?.datameta.job_id; // commented by raju due to its always taking first value
            let jobid_taskidArray = appStageDetails.filter(function (el) {
                return el.workflow_id === Number(workflow_id_job);
            });
            const task_id = jobid_taskidArray[0]?.datameta.task_id;
            const job_id = jobid_taskidArray[0]?.datameta.job_id;
            let appValues = JSON.stringify(annotationDetails);
            const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
            setTimeout(() => {
                iframe.id = 'iframeId';
                iframe.src = `${ANNOTATION_UI_URL}/tasks/${task_id}/jobs/${job_id}?auth=${appValues}`;
                iframe.setAttribute('allowFullScreen', '');
                iframe.style.position = 'absolute';
                iframe.style.height = '100%';
                iframe.style.width = '100%';
                iframe.style.zIndex = '999';
                iframe.style.top = '10';
                iframe.style.border = '0';
                iframe.style.backgroundColor = 'white';
                iframe.allow = 'clipboard-read; clipboard-write';
                wrapper.appendChild(iframe);
            }, 2000);
        }
    }, [isIframeOpen]);

    const columns = [
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
        },
        {
            title: 'JobId',
            dataIndex: 'JobId',
            key: 'JobId',
        },
        {
            title: 'View Task',
            dataIndex: 'ViewTask',
            key: 'ViewTask',
            render: (ViewTask: any) => {
                return (
                    <button className='customer-view-task' onClick={(e) => openIFrame(ViewTask, e)}>
                        View Task
                    </button>
                );
            },
        },
    ];
    let dataSource = appStageDetails.map((user) => ({ Status: user.status,JobId:user.datameta?.job_id, ViewTask: user.workflow_id }));

    const handleDelete = (vacationId, e) => {
        dispatch(fetchappStageReport(vacationId));
        if (e && e.stopPropagation) e.stopPropagation();
        setIsModalVisible(true);
    };
    const showSpinnerContent = () => {
        return <div id='previewCommentWrapper11'></div>;
    };

    useEffect(() => {
        return () => {
            dispatch(activateIframe(false));
            dispatch(activeIframeCustomer());
        };
    }, []);

    const onSearch = (value: any) => {};

    return (
        <>
            {isIframeOpen ? (
                showSpinnerContent()
            ) : (
                <>
                    <div className='customer-review-div'>Customer Review</div>
                    <div className='customer-review-body'>
                        <div className='select-customer-review-project'>
                            <label className='customer-review-select-project'>Select Project :</label>
                            <Select
                                placeholder='Select a Project'
                                style={{ width: '70%', margin: '0px 20px 20px 20px' }}
                                defaultValue={fetchReport}
                                onChange={projectHandler}
                                onSearch={onSearch}
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {projectsName.map(
                                    (project: any): JSX.Element => (
                                        <Select.Option value={project.id} key={project.id}>
                                            {project.name}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>

                            <Button
                                className='report-download'
                                disabled={fetchReport?.length === 0}
                                loading={loader}
                                onClick={() => reportHandler(fetchReport)}
                            >
                                <b>View Project</b>
                            </Button>

                            {exportData?.length > 0 ? (
                                <ExcelFile
                                    element={
                                        <Button className='report-download'>
                                            <Icon component={ReportDownloadIcon} />
                                            <b> Download</b>
                                        </Button>
                                    }
                                >
                                    <ExcelSheet data={exportData} name='ProjectsReports'>
                                        <ExcelColumn label='Project Name' value='projectName' />
                                        <ExcelColumn label='Project Stage Name' value='appAliasName' />
                                        <ExcelColumn label='Username' value='username' />
                                        <ExcelColumn label='Annotation Count' value='count' />
                                        <ExcelColumn label='Completed' value='completed' />
                                        <ExcelColumn label='Workflow Id' value='workflowId' />
                                        <ExcelColumn label='Job Id' value='jobId' />
                                        <ExcelColumn label='Parent WF' value='parentWf' />
                                        <ExcelColumn label='Status' value='status' />
                                    </ExcelSheet>
                                </ExcelFile>
                            ) : null}
                        </div>
                        <div className='report-stage-card'>
                            <Row gutter={[8, 8]}>
                                {customerStages?.length > 0 && customerStages ? (
                                    (customerStages.sort((a, b) => (a.stage > b.stage ? 1 : -1)),
                                    customerStages?.map(
                                        (app: any, index: number): JSX.Element => (
                                            <Col
                                                xs={24}
                                                lg={12}
                                                xl={10}
                                                xxl={6}
                                                className=''
                                                key={customerStages[index]}
                                            >
                                                <Card
                                                    className='ltts-item-card'
                                                    title={
                                                        <div className='Project-taskcount-text-align'>
                                                            <div className='ltts-project-card-layout'>
                                                                <div className='ltts-project-card-content'>
                                                                    <div className='report-stage-align'>
                                                                        <Tooltip
                                                                            placement='rightTop'
                                                                            title={app?.app_alias_name}
                                                                            overlayStyle={{ maxWidth: '500px' }}
                                                                        >
                                                                            <span aria-hidden>
                                                                                <Text
                                                                                    strong
                                                                                    className='ltts-project-item'
                                                                                >
                                                                                    Stage{' '}
                                                                                </Text>
                                                                                <span className='report-stage-order'>
                                                                                    {app?.stage}
                                                                                </span>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className='project-stage-name'>
                                                                        <Tooltip
                                                                            placement='rightTop'
                                                                            title={app?.app_alias_name}
                                                                            overlayStyle={{ maxWidth: '500px' }}
                                                                        >
                                                                            <span aria-hidden>
                                                                                <Text
                                                                                    strong
                                                                                    className='ltts-project-item'
                                                                                >
                                                                                    {' '}
                                                                                    Stage Name :{' '}
                                                                                </Text>
                                                                                <span>{app?.app_alias_name}</span>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                padding: '10px 50px 0px 20px',
                                                                            }}
                                                                        >
                                                                            <p className='stage-content'>
                                                                                Data Status:
                                                                            </p>
                                                                            <div>
                                                                                <Tooltip title={app?.app_alias_name}>
                                                                                    <Row
                                                                                        style={{
                                                                                            flexDirection: 'column',
                                                                                        }}
                                                                                    >
                                                                                        <>
                                                                                            <Col className='stage-status'>
                                                                                                <Text className='report-data-status report-new-color'>
                                                                                                    New
                                                                                                    <Text className='stage-data-status-count data-new-status'>
                                                                                                        {
                                                                                                            customerStages[
                                                                                                                index
                                                                                                            ]
                                                                                                                ?.data_summary
                                                                                                                ?.new
                                                                                                        }
                                                                                                    </Text>
                                                                                                </Text>
                                                                                            </Col>
                                                                                            <Col className='stage-status'>
                                                                                                <Text className='report-data-status report-progress-color'>
                                                                                                    In-Progress
                                                                                                    <Text className='stage-data-status-count data-progress-status'>
                                                                                                        {
                                                                                                            customerStages[
                                                                                                                index
                                                                                                            ]
                                                                                                                ?.data_summary
                                                                                                                ?.in_progress
                                                                                                        }
                                                                                                    </Text>
                                                                                                </Text>
                                                                                            </Col>
                                                                                            <Col className='stage-status'>
                                                                                                <Text className='report-data-status report-completed-color'>
                                                                                                    Completed
                                                                                                    <Text className='stage-data-status-count data-complete-status'>
                                                                                                        {
                                                                                                            customerStages[
                                                                                                                index
                                                                                                            ]
                                                                                                                ?.data_summary
                                                                                                                ?.completed
                                                                                                        }
                                                                                                    </Text>
                                                                                                </Text>
                                                                                            </Col>
                                                                                        </>
                                                                                    </Row>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p className='view-stage-report'>
                                                                        <Tooltip
                                                                            placement='rightTop'
                                                                            title={app?.app_alias_name}
                                                                            overlayStyle={{ maxWidth: '500px' }}
                                                                        >
                                                                            <div>
                                                                                <Button
                                                                                    className='report-download'
                                                                                    onClick={(e) =>
                                                                                        handleDelete(
                                                                                            customerStages[index]
                                                                                                .project_app_id,
                                                                                            e,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    View Details
                                                                                </Button>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                ></Card>
                                            </Col>
                                        ),
                                    ))
                                ) : (
                                    <Col className='gutter-row' span={6}>
                                        <p>No Annotation Stages Available!</p>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                </>
            )}

            <Modal
                title={
                    <>
                        <span className='customer-view-modal-exclaIcon'>
                            <ExclamationCircleOutlined />
                        </span>
                        <span>
                            <b>View Details</b>
                        </span>
                    </>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={'40%'}
                className='customer-view-details-modal'
            >
                <Table columns={columns} dataSource={dataSource} />
            </Modal>
        </>
    );
}
