// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import './dashboard.scss';
import { Menu, Button } from 'antd';
import Icon, {
    QuestionCircleOutlined,
    MenuUnfoldOutlined,
    ApartmentOutlined,
    DatabaseOutlined,
    AppstoreOutlined,
    SolutionOutlined,
    CodeSandboxOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { CustomerReviewIcon, ReportIcon, BMWReportIcon, OverallStatusIcon, } from 'icons';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';

interface Props {
    collapsed: any;
    toggleCollapsed: any;
    filteredArray: any;
    isIframeOpen: boolean;
}
function SideBar(props: Props): JSX.Element {
    const history = useHistory();
    const { collapsed, toggleCollapsed, filteredArray,isIframeOpen } = props;
    const userRole = useSelector((state: CombinedState) => state.auth.user);
    const location = useLocation();
useEffect(()=>{
    if(filteredArray[0] === 'user'){
        history.push('/appsstage')
    }
},[])
    return (
        <div>
            <Button key='toggle' className='collapse-icon' onClick={toggleCollapsed}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <Menu selectedKeys={[location.pathname]} mode='inline' className='navigation-bar' theme='dark' disabled={isIframeOpen}>
                {userRole.isSuperuser && (
                    <Menu.Item
                        key='/organizations-create'
                        style={{ fontSize: '18px' }}
                        disabled={isIframeOpen}
                        onClick={(): void => history.push('/organizations-create')}
                    >
                        <ApartmentOutlined className='IconAlign' /> Organization
                    </Menu.Item>
                )}
                {(filteredArray[0] === 'admin' || filteredArray[0] === 'project-admin') && (
                    <Menu.Item
                        key='/projects'
                        style={{ fontSize: '18px' }}
                        onClick={(): void => history.push('/projects')}
                    >
                        <DatabaseOutlined className='IconAlignProject' />
                        Projects
                    </Menu.Item>
                )}

                <Menu.Item
                    key='/appsstage'
                    style={{ fontSize: '18px' }}
                    disabled={isIframeOpen}
                    onClick={(): void => history.push('/appsstage')}
                >
                    <AppstoreOutlined className='IconAlign' /> Apps
                </Menu.Item>

                {(filteredArray[0] === 'admin' || filteredArray[0] === 'project-admin') && (
                    <Menu.Item
                        key='/userlist'
                        style={{ fontSize: '18px' }}
                        onClick={(): void => history.push('userlist')}
                    >
                        <SolutionOutlined className='IconAlign' /> User List
                    </Menu.Item>
                )}
                 {userRole.isSuperuser && (
                    <Menu.Item
                        key='/overall-status'
                        style={{ fontSize: '18px' }}
                        onClick={(): void => history.push('/overall-status')}
                    >
                        <Icon className='IconAlign' component={OverallStatusIcon} />Overall Status
                    </Menu.Item>
                )}
                {userRole.isSuperuser && (
                    <Menu.Item key='models' style={{ fontSize: '18px' }}>
                        <CodeSandboxOutlined className='IconAlign' /> Models
                    </Menu.Item>
                )}
                {userRole.isSuperuser && (
                    <Menu.Item
                        key='/reports'
                        style={{ fontSize: '18px' }}
                        onClick={(): void => history.push('/reports')}
                    >
                        <Icon className='IconAlign' component={ReportIcon} /> Reports
                    </Menu.Item>
                )}
                {userRole.isSuperuser && (
                    <Menu.Item
                        key='/customer-review'
                        style={{ fontSize: '18px', height: '60px' }}
                        onClick={(): void => history.push('/customer-review')}
                    >
                        <Icon style={{ paddingLeft: '2px' }} className='IconAlign' component={CustomerReviewIcon} />
                        <text>
                            <span>Customer </span>
                            <span style={{ marginLeft: '47px', display: 'block', marginTop: '-10px' }}>Review</span>
                        </text>
                    </Menu.Item>
                )}
                {userRole.isSuperuser && (
                    <Menu.Item
                        key='/bmw-reports'
                        style={{ fontSize: '18px', height: '60px' }}
                        onClick={(): void => history.push('/bmw-reports')}
                    >
                        <Icon style={{ paddingLeft: '2px' }} className='IconAlign' component={BMWReportIcon} />
                        <text>
                            <span>BMW </span>
                            <span style={{ marginLeft: '47px', display: 'block', marginTop: '-10px' }}>Reports</span>
                        </text>
                    </Menu.Item>
                )}
                <Menu.Item key='Help' style={{ fontSize: '18px', bottom: '0', marginBottom: '2em', marginTop: '87px' }}>
                    <QuestionCircleOutlined className='IconAlign' /> Help ?
                </Menu.Item>
            </Menu>
        </div>
    );
}

export default SideBar;
