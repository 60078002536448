// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import './reports-styles.scss';
import React, { useEffect } from 'react';
import ReportProjectComponent from './report-project-page';
import { useSelector, useDispatch } from 'react-redux';
import { getProjectReportStatus, getProjectsName, getUsersTotalTime, resetReportState } from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';
import { Tabs, Typography } from 'antd';
import UserTable from './report-user-page';
import Timeontool from './time-on-tool-page';
const { TabPane } = Tabs;
const { Text } = Typography;

export default function ReportsComponent(): JSX.Element {
    const dispatch = useDispatch();
    let projectStatus: any = [];

    useEffect(() => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate());
        dispatch(getUsersTotalTime(date, date));
        dispatch(resetReportState());
        dispatch(getProjectReportStatus());
        dispatch(getProjectsName());
    }, [dispatch]);

    projectStatus = useSelector((state: CombinedState) => state.reports.projectDataStatus);
    let projectsName = useSelector((state: CombinedState) => state.reports.projectsName);
    return (
        <div className='report-main-page'>
            <Text className='report-title'>Reports</Text>
            <Tabs defaultActiveKey='Projects' className='tab-report'>
                <TabPane tab='Organization' key='Organization' className='tab-pane-holder-report'>
                    Organization
                </TabPane>
                <TabPane tab='User' key='User' className='tab-pane-holder-report'>
                    <UserTable/>
                </TabPane>
                <TabPane tab='Projects' key='Projects' className='tab-pane-holder-report'>
                    <ReportProjectComponent projectData={projectStatus} projectsName={projectsName} />
                </TabPane>
                <TabPane tab='TOT' key='TOT' className='tab-pane-timeontool'>
                    <Timeontool/>
                </TabPane>
            </Tabs>
        </div>
    );
}
