// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import SVGCVATLogo from './assets/ltts-logo.svg';
import SVGEmptyTasksIcon from './assets/empty-tasks-icon.svg';
import SVGCursorIcon from './assets/cursor-icon.svg';
import SVGMoveIcon from './assets/move-icon.svg';
import SVGRotateIcon from './assets/rotate-icon.svg';
import SVGFitIcon from './assets/fit-to-window-icon.svg';
import SVGZoomIcon from './assets/zoom-icon.svg';
import SVGRectangleIcon from './assets/rectangle-icon.svg';
import SVGPolygonIcon from './assets/polygon-icon.svg';
import SVGPointIcon from './assets/point-icon.svg';
import SVGEllipseIcon from './assets/ellipse-icon.svg';
import SVGPolylineIcon from './assets/polyline-icon.svg';
import SVGTagIcon from './assets/tag-icon.svg';
import SVGTagSmallIcon from './assets/tagIcon.svg';
import SVGMergeIcon from './assets/merge-icon.svg';
import SVGGroupIcon from './assets/group-icon.svg';
import SVGSplitIcon from './assets/split-icon.svg';
import SVGMainMenuIcon from './assets/main-menu-icon.svg';
import SVGSaveIcon from './assets/save-icon.svg';
import SVGUndoIcon from './assets/undo-icon.svg';
import SVGRedoIcon from './assets/redo-icon.svg';
import SVGFirstIcon from './assets/first-icon.svg';
import SVGBackJumpIcon from './assets/back-jump-icon.svg';
import SVGPreviousIcon from './assets/previous-icon.svg';
import SVGPreviousFilteredIcon from './assets/previous-filtered-icon.svg';
import SVGPreviousEmptyIcon from './assets/previous-empty-icon.svg';
import SVGPlayIcon from './assets/play-icon.svg';
import SVGPauseIcon from './assets/pause-icon.svg';
import SVGNextIcon from './assets/next-icon.svg';
import SVGNextFilteredIcon from './assets/next-filtered-icon.svg';
import SVGNextEmptyIcon from './assets/next-empty-icon.svg';
import SVGForwardJumpIcon from './assets/forward-jump-icon.svg';
import SVGLastIcon from './assets/last-icon.svg';
import SVGInfoIcon from './assets/info-icon.svg';
import SVGFullscreenIcon from './assets/fullscreen-icon.svg';
import SVGObjectOutsideIcon from './assets/object-outside-icon.svg';
import SVGBackgroundIcon from './assets/background-icon.svg';
import SVGForegroundIcon from './assets/foreground-icon.svg';
import SVGCubeIcon from './assets/cube-icon.svg';
import SVGResetPerspectiveIcon from './assets/reset-perspective.svg';
import SVGColorizeIcon from './assets/colorize-icon.svg';
import SVGAITools from './assets/ai-tools-icon.svg';
import SVGBrain from './assets/brain.svg';
import SVGOpenCV from './assets/opencv.svg';
import SVGFilterIcon from './assets/object-filter-icon.svg';
import SVGCVATAzureProvider from './assets/vscode-icons_file-type-azure.svg';
import SVGCVATS3Provider from './assets/S3.svg';
import SVGCVATGoogleCloudProvider from './assets/google-cloud.svg';
import SVGOpenVINO from './assets/openvino.svg';
import SVGaudioIcon from './assets/audio-icon.svg';
import SVGimageIcon from './assets/image-icon.svg';
import SVGvideoIcon from './assets/video-icon.svg';
import SVGlidarIcon from './assets/lidar-icon.svg';
import SVGsortIcon from './assets/sort-icon.svg';
import SVGProfileIcon from './assets/profile-icon.svg';
import SVGAnnotIcon from './assets/AnnotAI-New-Reduced-Logo.svg';
import SVGProjectFilterIcon from './assets/filter-icon.svg';
import SVGProjectSortIcon from './assets/Sorting-icon.svg';
import SVGProjectCardIcon from './assets/card-icon.svg';
import SVGProjectCardActiveIcon from './assets/card-icon-active.svg';
import SVGProjectListActiveIcon from './assets/list-icon-active.svg';
import SVGProjectListIcon from './assets/list-icon.svg';
import SVGProjectImageIcon from './assets/project-image-icon.svg';
import SVGProjectVideoIcon from './assets/project-video-icon.svg';
import SVGProjectAudioIcon from './assets/project-audio-icon.svg';
import SVGProjectLidarIcon from './assets/project-lidar-icon.svg';
import SVGUploadFileIcon from './assets/upload-file-icon.svg';
import SVGUploadImageIcon from './assets/upload-image-icon.svg';
import SVGUploadVideoIcon from './assets/upload-video-icon.svg';
import SVGInformationIcon from './assets/information-icon.svg';
import SVGDownloadIcon from './assets/download.svg';
import SVGExportIcon from './assets/export-icon.svg';
import SVGDeleteIcon from './assets/delete-icon.svg';
import SVGInfoBlackIcon from './assets/info-black-icon.svg';
import SVGExportLiDarIcon from './assets/LiDAR.svg';
import SVGExportAudioIcon from './assets/Audio.svg';
import SVGUserIcon from './assets/user-icon.svg';
import SVGUserFilterIcon from './assets/filter-icon2.svg';
import SVGOfflineStatusIcon from './assets/offline-status.svg';
import SVGProjectCountIcon from './assets/project-count-icon.svg';
import SVGProjectProgressIcon from './assets/progress-icon.svg';
import SVGProjectCompletedIcon from './assets/completed-icon.svg';
import SVGActiveStatusIcon from './assets/active-status.svg';
import SVGReportIcon from './assets/report-icon.svg';
import SVGCardSelectedIcon from './assets/card-selected-icon.svg';
import SVGListSelectedIcon from './assets/list-selected-icon.svg';
import SVGRejectIcon from './assets/reject-icon.svg';
import SVGSaveAndCloseIcon from './assets/save-close-icon.svg';
import SVGSubmitAndCloseIcon from './assets/submit-close-icon.svg';
import SVGSubmitLoadNextIcon from './assets/submit-load-next-icon.svg';
import SVGCuboidIcon from './assets/cuboid-shape-icon.svg';
import SVGPolyLineIcon from './assets/pointcloud-shape-icon.svg';
import SVGPolygonShapeIcon from './assets/polygon-shape-icon.svg';
import SVGRectangleShapeIcon from './assets/rectangle-shape-icon.svg';
import SVGReportDownloadIcon from './assets/report-download-icon.svg';
import SVGPointShapeIcon from './assets/pointIcon.svg';
import SVGEllipseshapeIcon from './assets/ellipseIcon.svg';
import SVGProjectInfoIcon from './assets/Project-info-icon.svg';
import SVGCustomerReviewIcon from './assets/CustomerReviewIconlatest.svg';
import SVGBMWReportIcon from './assets/bmw-report-icon.svg';
import SVGBrushIcon from './assets/brush-icon.svg';
import SVGOverallStatusIcon from "./assets/overall-status-icon.svg";
import SVGSessionDownloadErrorIcon from "./assets/session-download-error.svg";
import SVGSessionFailedIcon from "./assets/session-failed-icon.svg";
import SVGSessionSuccessIcon from "./assets/session-success-icon.svg";
import SVGSessionTriggerIcon from "./assets/session-trigger-icon.svg";
import SVGSessionInProgressIcon from "./assets/session-inprogress-icon.svg";
import SVGSessionExceptionIcon from "./assets/session-exception-icon.svg";
import SVGSessionFilterIcon from "./assets/session-filter-icon.svg";
import SVGSessionCorrectedIcon from "./assets/session-corrected-icon.svg";
export const ActiveStatusLogo = React.memo((): JSX.Element => <SVGActiveStatusIcon />);
export const UserFilterLogo = React.memo((): JSX.Element => <SVGUserFilterIcon />);
export const OfflineStatusLogo = React.memo((): JSX.Element => <SVGOfflineStatusIcon />);
export const UserLogo = React.memo((): JSX.Element => <SVGUserIcon />);
export const CVATLogo = React.memo((): JSX.Element => <SVGCVATLogo />);
export const EmptyTasksIcon = React.memo((): JSX.Element => <SVGEmptyTasksIcon />);
export const CursorIcon = React.memo((): JSX.Element => <SVGCursorIcon />);
export const MoveIcon = React.memo((): JSX.Element => <SVGMoveIcon />);
export const RotateIcon = React.memo((): JSX.Element => <SVGRotateIcon />);
export const FitIcon = React.memo((): JSX.Element => <SVGFitIcon />);
export const ZoomIcon = React.memo((): JSX.Element => <SVGZoomIcon />);
export const RectangleIcon = React.memo((): JSX.Element => <SVGRectangleIcon />);
export const PolygonIcon = React.memo((): JSX.Element => <SVGPolygonIcon />);
export const PointIcon = React.memo((): JSX.Element => <SVGPointIcon />);
export const EllipseIcon = React.memo((): JSX.Element => <SVGEllipseIcon />);
export const PolylineIcon = React.memo((): JSX.Element => <SVGPolylineIcon />);
export const TagIcon = React.memo((): JSX.Element => <SVGTagIcon />);
export const MergeIcon = React.memo((): JSX.Element => <SVGMergeIcon />);
export const GroupIcon = React.memo((): JSX.Element => <SVGGroupIcon />);
export const SplitIcon = React.memo((): JSX.Element => <SVGSplitIcon />);
export const MainMenuIcon = React.memo((): JSX.Element => <SVGMainMenuIcon />);
export const SaveIcon = React.memo((): JSX.Element => <SVGSaveIcon />);
export const UndoIcon = React.memo((): JSX.Element => <SVGUndoIcon />);
export const RedoIcon = React.memo((): JSX.Element => <SVGRedoIcon />);
export const FirstIcon = React.memo((): JSX.Element => <SVGFirstIcon />);
export const BackJumpIcon = React.memo((): JSX.Element => <SVGBackJumpIcon />);
export const PreviousIcon = React.memo((): JSX.Element => <SVGPreviousIcon />);
export const PreviousFilteredIcon = React.memo((): JSX.Element => <SVGPreviousFilteredIcon />);
export const PreviousEmptyIcon = React.memo((): JSX.Element => <SVGPreviousEmptyIcon />);
export const PauseIcon = React.memo((): JSX.Element => <SVGPauseIcon />);
export const PlayIcon = React.memo((): JSX.Element => <SVGPlayIcon />);
export const NextIcon = React.memo((): JSX.Element => <SVGNextIcon />);
export const NextFilteredIcon = React.memo((): JSX.Element => <SVGNextFilteredIcon />);
export const NextEmptyIcon = React.memo((): JSX.Element => <SVGNextEmptyIcon />);
export const ForwardJumpIcon = React.memo((): JSX.Element => <SVGForwardJumpIcon />);
export const LastIcon = React.memo((): JSX.Element => <SVGLastIcon />);
export const InfoIcon = React.memo((): JSX.Element => <SVGInfoIcon />);
export const FullscreenIcon = React.memo((): JSX.Element => <SVGFullscreenIcon />);
export const ObjectOutsideIcon = React.memo((): JSX.Element => <SVGObjectOutsideIcon />);
export const BackgroundIcon = React.memo((): JSX.Element => <SVGBackgroundIcon />);
export const ForegroundIcon = React.memo((): JSX.Element => <SVGForegroundIcon />);
export const CubeIcon = React.memo((): JSX.Element => <SVGCubeIcon />);
export const ResetPerspectiveIcon = React.memo((): JSX.Element => <SVGResetPerspectiveIcon />);
export const AIToolsIcon = React.memo((): JSX.Element => <SVGAITools />);
export const ColorizeIcon = React.memo((): JSX.Element => <SVGColorizeIcon />);
export const BrainIcon = React.memo((): JSX.Element => <SVGBrain />);
export const OpenCVIcon = React.memo((): JSX.Element => <SVGOpenCV />);
export const FilterIcon = React.memo((): JSX.Element => <SVGFilterIcon />);
export const AzureProvider = React.memo((): JSX.Element => <SVGCVATAzureProvider />);
export const S3Provider = React.memo((): JSX.Element => <SVGCVATS3Provider />);
export const GoogleCloudProvider = React.memo((): JSX.Element => <SVGCVATGoogleCloudProvider />);
export const OpenVINOIcon = React.memo((): JSX.Element => <SVGOpenVINO />);
export const SortIcon = React.memo((): JSX.Element => <SVGsortIcon />);
export const AudioIcon = React.memo((): JSX.Element => <SVGaudioIcon />);
export const ImageIcon = React.memo((): JSX.Element => <SVGimageIcon />);
export const VideoIcon = React.memo((): JSX.Element => <SVGvideoIcon />);
export const LidarIcon = React.memo((): JSX.Element => <SVGlidarIcon />);
export const ProfileIcon = React.memo((): JSX.Element => <SVGProfileIcon />);
export const AnnotIcon = React.memo((): JSX.Element => <SVGAnnotIcon />);
// export const AnnotLogoIcon = React.memo((): JSX.Element => <SVGAnnotLogoIcon />);
export const ProjectFilterIcon = React.memo((): JSX.Element => <SVGProjectFilterIcon />);
export const ProjectSortIcon = React.memo((): JSX.Element => <SVGProjectSortIcon />);
export const ProjectCardIcon = React.memo((): JSX.Element => <SVGProjectCardIcon />);
export const ProjectListIcon = React.memo((): JSX.Element => <SVGProjectListIcon />);
export const ProjectCardActiveIcon = React.memo((): JSX.Element => <SVGProjectCardActiveIcon />);
export const ProjectListActiveIcon = React.memo((): JSX.Element => <SVGProjectListActiveIcon />);
export const ProjectImageIcon = React.memo((): JSX.Element => <SVGProjectImageIcon />);
export const ProjectVideoIcon = React.memo((): JSX.Element => <SVGProjectVideoIcon />);
export const ProjectAudioIcon = React.memo((): JSX.Element => <SVGProjectAudioIcon />);
export const ProjectLidarIcon = React.memo((): JSX.Element => <SVGProjectLidarIcon />);
export const UploadFileIcon = React.memo((): JSX.Element => <SVGUploadFileIcon />);
export const UploadImageIcon = React.memo((): JSX.Element => <SVGUploadImageIcon />);
export const UploadVideoIcon = React.memo((): JSX.Element => <SVGUploadVideoIcon />);
export const InformationIcon = React.memo((): JSX.Element => <SVGInformationIcon />);
export const DownloadIcon = React.memo((): JSX.Element => <SVGDownloadIcon />);
export const ExportIcon = React.memo((): JSX.Element => <SVGExportIcon />);
export const DeleteIcon = React.memo((): JSX.Element => <SVGDeleteIcon />);
export const InfoBlackIcon = React.memo((): JSX.Element => <SVGInfoBlackIcon />);
export const ExportLiDarIcon = React.memo((): JSX.Element => <SVGExportLiDarIcon/>);
export const ExportAudioIcon = React.memo((): JSX.Element => <SVGExportAudioIcon />);
export const ProjectCountIcon = React.memo((): JSX.Element => <SVGProjectCountIcon />);
export const ReportIcon = React.memo((): JSX.Element => <SVGReportIcon />);
export const ProjectProgressIcon = React.memo((): JSX.Element => <SVGProjectProgressIcon />);
export const ProjectCompletedIcon = React.memo((): JSX.Element => <SVGProjectCompletedIcon />);
export const CardSelectedIcon = React.memo((): JSX.Element => <SVGCardSelectedIcon />);
export const ListSelectedIcon = React.memo((): JSX.Element => <SVGListSelectedIcon />)
export const RejectIcon = React.memo((): JSX.Element => <SVGRejectIcon />)
export const SaveAndCloseIcon = React.memo((): JSX.Element => <SVGSaveAndCloseIcon />)
export const SubmitAndCloseIcon = React.memo((): JSX.Element => <SVGSubmitAndCloseIcon />)
export const SubmitLoadNextIcon = React.memo((): JSX.Element => <SVGSubmitLoadNextIcon />)
export const CuboidIcon = React.memo((): JSX.Element => <SVGCuboidIcon />)
export const PolyLineIcon = React.memo((): JSX.Element => <SVGPolyLineIcon />)
export const PolygonShapeIcon = React.memo((): JSX.Element => <SVGPolygonShapeIcon />)
export const RectangleShapeIcon = React.memo((): JSX.Element => <SVGRectangleShapeIcon />)
export const EllipseShapeIcon = React.memo((): JSX.Element => <SVGEllipseshapeIcon />)
export const PointShapeIcon = React.memo((): JSX.Element => <SVGPointShapeIcon />)
export const TagSmallIcon = React.memo((): JSX.Element => <SVGTagSmallIcon />);
export const ProjectInfoIcon = React.memo((): JSX.Element => <SVGProjectInfoIcon />);
export const CustomerReviewIcon = React.memo((): JSX.Element => <SVGCustomerReviewIcon />);
export const ReportDownloadIcon = React.memo((): JSX.Element => <SVGReportDownloadIcon />);
export const BMWReportIcon = React.memo((): JSX.Element => <SVGBMWReportIcon />);
export const BrushIcon = React.memo((): JSX.Element => <SVGBrushIcon />);
export const OverallStatusIcon = React.memo((): JSX.Element => <SVGOverallStatusIcon />);
export const SessionDownloadError = React.memo((): JSX.Element => <SVGSessionDownloadErrorIcon />);
export const SessionFailedIcon = React.memo((): JSX.Element => <SVGSessionFailedIcon />);
export const SessionSuccessIcon = React.memo((): JSX.Element => <SVGSessionSuccessIcon />);
export const SessionTriggerIcon = React.memo((): JSX.Element => <SVGSessionTriggerIcon />);
export const SessionInProgressIcon = React.memo((): JSX.Element => <SVGSessionInProgressIcon />);
export const SessionExceptionIcon = React.memo((): JSX.Element => <SVGSessionExceptionIcon />);
export const SessionFilterIcon  = React.memo((): JSX.Element => <SVGSessionFilterIcon />);
export const SessionCorrectedIcon  = React.memo((): JSX.Element => <SVGSessionCorrectedIcon />);
