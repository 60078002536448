// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState, useCallback } from 'react';
import Text from 'antd/lib/typography/Text';
import Card from 'antd/lib/card';
import Button from 'antd/lib/button';
import { Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import { activateIframe, fetchWorkflowMapping } from 'actions/apps-stages-actions';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectImageIcon, ProjectVideoIcon, ProjectAudioIcon, ProjectLidarIcon } from 'icons';
import { CombinedState } from 'reducers/interfaces';
interface Props {
    projectInstance: any;
}
export default function AppStageItemComponent(props: Props): JSX.Element {
    const {
        projectInstance: {
            id,
            app_id,
            project_app_id,
            app_alias_name,
            project_name,
            status,
            project_type: projectType,
        },
    } = props;

    const dispatch = useDispatch();
    const userRole = useSelector((state: CombinedState) => state.auth.user);
    const openFrame = (event) => {
        sessionStorage.removeItem("isopen_status");
        event.preventDefault();
        let today = new Date();
        let start_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        dispatch(fetchWorkflowMapping({ app_id, project_app_id, start_time, userRole }));
    };

    const showProjectType = () => {
        let iconObj = {
            icon: '',
            text: '',
            className: '',
        };
        if (projectType === 1) {
            iconObj = {
                icon: ProjectImageIcon,
                text: 'Image Annotation',
                className: 'Project-icon-image',
            };
        } else if (projectType === 2) {
            iconObj = {
                icon: ProjectVideoIcon,
                text: 'Video Annotation',
                className: 'Project-icon-video',
            };
        } else if (projectType === 3) {
            iconObj = {
                icon: ProjectLidarIcon,
                text: 'LiDAR Annotation',
                className: 'Project-icon-lidar',
            };
        } else if (projectType === 4) {
            iconObj = {
                icon: ProjectAudioIcon,
                text: 'Audio Annotation',
                className: 'Project-icon-audio',
            };
        }
        return (
            <div className={iconObj.className}>
                <Row align='middle' gutter={[16, 16]}>
                    <Col span={6}>
                        <Icon component={iconObj.icon} />
                    </Col>
                    <Col span={6} style={{ fontWeight: 'bold' }}>
                        {iconObj.text}
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <>
            <Card
                key={id}
                className='ltts-item-card'
                size='small'
                title={
                    <div className='Project-taskcount-text-align'>
                        {showProjectType()}
                        <div className='ltts-project-card-layout'>
                            <div className='ltts-project-card-content'>
                                <p>
                                    <span aria-hidden>
                                        <Text className='ltts-project-item'>App Name: </Text>
                                        {app_alias_name}
                                    </span>
                                </p>
                                <p>
                                    <span aria-hidden>
                                        <Text strong>Project Status: </Text>
                                        {status}
                                    </span>
                                </p>
                                <p>
                                    <span aria-hidden>
                                        <Text strong>Project Name: </Text>
                                        {project_name}
                                    </span>
                                </p>
                            </div>
                            <div className='ltts-apps-open-button'>
                                <Button
                                    type='primary'
                                    size='small'
                                    className='ltts-create-project-button'
                                    onClick={(e: React.MouseEvent): void => openFrame(e)}
                                >
                                    Open
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            ></Card>
        </>
    );
}
