import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { OverallStatusSagaActionTypes } from '../actions/overall-status-actions';

const Axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

async function getSessionData() {
    const response = await Axios.get(`${API_URL}/api/v1/sessions`);
    return response.data;
}

function* getSessionWiseData(): any {
    try {
        const response = yield call(getSessionData);
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_WISE_DATA_SUCCESS, response });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_WISE_DATA_FAILED, message: e });
    }
}

async function getStatusData(status: string) {
    const response = await Axios.get(`${API_URL}/api/v1/sessions?status=${status}`);
    return response.data;
}

function* getSessionStatusData(data:any): any {
    const status = data.payload.status;
    try {
        const response = yield call(getStatusData, status);
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA_SUCCESS, response });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA_FAILED, message: e });
    }
}

async function getTicketData(ticket: string) {
    const response = await Axios.get(`${API_URL}/api/v1/ticket?ticket=${ticket}`);
    return response.data;
}

function* getSessionTickeData(data:any): any {
    const ticket = data.payload.ticket;
    try {
        const response = yield call(getTicketData, ticket);
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA_SUCCESS, response });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA_FAILED, message: e });
    }
}

async function submitCorrected(correctedSessions: any[]) {
    const response = await Axios.put(`${API_URL}/api/v1/sessions/bulk-update`, correctedSessions);
    return response.data;
}

function* submitCorrectedSessions(data:any): any {
    const session = data.payload.correctedSessions;
    try {
        const response = yield call(submitCorrected, session);
        yield put({ type: OverallStatusSagaActionTypes.SUBMIT_CORRECTED_SESSIONS_SUCCESS, response });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.SUBMIT_CORRECTED_SESSIONS_FAILED, message: e });
    }
}

async function downloadFile(id: any) {
    let x = window.open(`${API_URL}/api/v1/sessions/${id}/download`, '_blank');
    // x?.close();  // To close the new opened tab
}

function* downloadErrorFile(data: any): any {
    const id = data?.payload?.id;
    try {
        yield call(downloadFile, id);
        yield put({ type: OverallStatusSagaActionTypes.DOWNLOAD_ERROR_FILE_SUCCESS, id });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.DOWNLOAD_ERROR_FILE_FAILED, message: e });
    }
}

async function getDateWiseData(start, end) {
    let response = null;
    if (start && end) {
        response = await Axios.get(`${API_URL}/api/daywise?from=${start}&to=${end}`);
    }
    if (response.data) {
        return response.data;
    }
    return response;
}

function* getSessionDateWiseData(data): any {
    const start = data.payload.start;
    const end = data.payload.end;

    try {
        const response = yield call(getDaysData, start, end);
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_DATEWISE_DATA_SUCCESS, response });
    } catch (e) {
        yield put({ type: OverallStatusSagaActionTypes.GET_SESSION_DATEWISE_DATA_FAILED, message: e });
    }
}

export function* sessionWatcher() {
    yield all([takeEvery(OverallStatusSagaActionTypes.GET_SESSION_WISE_DATA, getSessionWiseData)]);
    yield all([takeEvery(OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA, getSessionStatusData)]);
    yield all([takeEvery(OverallStatusSagaActionTypes.GET_SESSION_TICKET_DATA, getSessionTickeData)]);
    yield all([takeEvery(OverallStatusSagaActionTypes.SUBMIT_CORRECTED_SESSIONS, submitCorrectedSessions)]);
    yield all([takeEvery(OverallStatusSagaActionTypes.DOWNLOAD_ERROR_FILE, downloadErrorFile)]);
}
