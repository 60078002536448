// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
export enum OverallStatusSagaActionTypes {
    GET_SESSION_WISE_DATA ='GET_SESSION_WISE_DATA',
    GET_SESSION_WISE_DATA_SUCCESS = 'GET_SESSION_WISE_DATA_SUCCESS',
    GET_SESSION_WISE_DATA_FAILED = 'GET_SESSION_WISE_DATA_FAILED',
    GET_SESSION_STATUS_DATA='GET_SESSION_STATUS_DATA',
    GET_SESSION_STATUS_DATA_SUCCESS = 'GET_SESSION_STATUS_DATA_SUCCESS',
    GET_SESSION_STATUS_DATA_FAILED = 'GET_SESSION_STATUS_DATA_FAILED',
    GET_SESSION_DATEWISE_DATA='GET_SESSION_DATEWISE_DATA',
    GET_SESSION_DATEWISE_DATA_SUCCESS = 'GET_SESSION_DATEWISE_DATA_SUCCESS',
    GET_SESSION_DATEWISE_DATA_FAILED = 'GET_SESSION_DATEWISE_DATA_FAILED',
    GET_SESSION_TICKET_DATA='GET_SESSION_TICKET_DATA',
    GET_SESSION_TICKET_DATA_SUCCESS = 'GET_SESSION_TICKET_DATA_SUCCESS',
    GET_SESSION_TICKET_DATA_FAILED = 'GET_SESSION_TICKET_DATA_FAILED',
    SUBMIT_CORRECTED_SESSIONS ='SUBMIT_CORRECTED_SESSIONS',
    SUBMIT_CORRECTED_SESSIONS_SUCCESS ='SUBMIT_CORRECTED_SESSIONS_SUCCESS',
    SUBMIT_CORRECTED_SESSIONS_FAILED ='SUBMIT_CORRECTED_SESSIONS_FAILED',
    DOWNLOAD_ERROR_FILE='DOWNLOAD_ERROR_FILE',
    DOWNLOAD_ERROR_FILE_SUCCESS='DOWNLOAD_ERROR_FILE_SUCCESS',
    DOWNLOAD_ERROR_FILE_FAILED='DOWNLOAD_ERROR_FILE_FAILED',
}

export function getSessionWiseData() {
    return {
        type: OverallStatusSagaActionTypes.GET_SESSION_WISE_DATA,
    };
}

export function getSessionStatusData(status:string) {
    return {
        type: OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA,
        payload: {status}
    };
}

export function getSessionTicketData(ticket:string) {
    return {
        type: OverallStatusSagaActionTypes.GET_SESSION_TICKET_DATA,
        payload: {ticket}
    };
}

export function submitCorrectedSessions(correctedSessions:any[]) {
    return {
        type: OverallStatusSagaActionTypes.SUBMIT_CORRECTED_SESSIONS,
        payload: {correctedSessions}
    };
}

export function getSessionDateWiseData(start, end) {
    return {
        type: OverallStatusSagaActionTypes.GET_SESSION_DATEWISE_DATA,
        payload: {start, end}
    };
}

export function downloadErrorFile(id:any) {
    return {
        type: OverallStatusSagaActionTypes.DOWNLOAD_ERROR_FILE,
        payload: {id}
    };
}
