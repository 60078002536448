import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Spinner from 'components/loader/loader';
import Text from 'antd/lib/typography/Text';
import 'antd/dist/antd.css';

import LoginPageContainer from 'containers/login-page/login-page';
import LoginWithTokenComponent from 'components/login-with-token/login-with-token';
import RegisterPageContainer from 'containers/register-page/register-page';
import ResetPasswordPageConfirmComponent from 'components/reset-password-confirm-page/reset-password-confirm-page';
import ResetPasswordPageComponent from 'components/reset-password-page/reset-password-page';

import GlobalErrorBoundary from 'components/global-error-boundary/global-error-boundary';

import ShortcutsDialog from 'components/shortcuts-dialog/shortcuts-dialog';
import ExportDatasetModal from 'components/export-dataset/export-dataset-modal';
import ModelsPageContainer from 'containers/models-page/models-page';

import JobsPageComponent from 'components/jobs-page/jobs-page';

import TasksPageContainer from 'containers/tasks-page/tasks-page';
import CreateTaskPageContainer from 'containers/create-task-page/create-task-page';
import TaskPageContainer from 'containers/task-page/task-page';

// import ProjectsPageComponent from 'components/projects-page/projects-page';
import CreateProjectPageComponent from 'components/project-mgmt-page/create-project/create-project-page';
import ProjectPageComponent from 'components/project-page/project-page';
import FileManager from 'containers/file-manager/file-manager';
import ProjectDatasetComponent from 'components/project-mgmt-page/project-dataset';

import CloudStoragesPageComponent from 'components/cloud-storages-page/cloud-storages-page';
import CreateCloudStoragePageComponent from 'components/create-cloud-storage-page/create-cloud-storage-page';
import UpdateCloudStoragePageComponent from 'components/update-cloud-storage-page/update-cloud-storage-page';

import OrganizationPage from 'components/organization-page/organization-page';
import CreateOrganizationPageComponent from 'components/organizations-page/organizations-details-page';
import CreateOrganizationComponent from 'components/create-organization-page/create-organization-page';
import AnnotationPageContainer from 'containers/annotation-page/annotation-page';
import getCore from 'ltts-core-wrapper';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { NotificationsState } from 'reducers/interfaces';
import { customWaViewHit } from 'utils/enviroment';
import showPlatformNotification, {
    platformInfo,
    stopNotifications,
    showUnsupportedNotification,
} from 'utils/platform-checker';
import '../styles.scss';
import EmailConfirmationPage from './email-confirmation-page/email-confirmed';
import Dashboard from './dashboard/main';
import HeaderComponent from './dashboard/header';
import ProjectCreationStepper from './project-mgmt-page/create-project/project-modal';
import SideBar from './dashboard/side-bar';
import FooterComponent from './dashboard/footer';
import AgreementConfirmationPage from '../components/useragreements/user-acceptance-form';
import AppsPageComponent from './dashboard/apps-stages/apps-stages-main';
import ReportsComponent from './dashboard/reports/report-main-page';
import OverallStatusComponent from './dashboard/overall-status/overall-status-main-page';
import ProjectApps from './project-mgmt-page/project-apps/project-apps';
import editProject from './project-mgmt-page/edit-project/edit-project-page';
import projectAdmin from './project-mgmt-page/project-admin/project-admin';
import UserListComponent from './user-list-page/user-list';
import CreateProjectDetails from './project-mgmt-page/create-project/create-project-details';
import CustomerReviewComponent from './dashboard/customer-review/customer-review';
import BMWReportsComponent from './dashboard/bmw-reports/bmw-ticket-reports';
const { Content, Sider } = Layout;

interface CVATAppProps {
    loadFormats: () => void;
    loadAbout: () => void;
    verifyAuthorized: () => void;
    triggerIFrame: () => void;
    loadUserAgreements: () => void;
    initPlugins: () => void;
    initModels: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    switchSettingsDialog: () => void;
    loadAuthActions: () => void;
    loadOrganizations: () => void;
    keyMap: KeyMap;
    userInitialized: boolean;
    userFetching: boolean;
    organizationsFetching: boolean;
    organizationsInitialized: boolean;
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    userAgreementsFetching: boolean;
    userAgreementsInitialized: boolean;
    authActionsFetching: boolean;
    authActionsInitialized: boolean;
    notifications: NotificationsState;
    user: any;
    isModelPluginActive: boolean;
    userGroups: any;
    isIframeOpen: boolean;
    customerIframe: boolean;
}

class CVATApplication extends React.PureComponent<CVATAppProps & RouteComponentProps> {
    state = {
        collapsed: false,
        filteredUserRole: ['user'],
    };

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    toggleSideBar = () => {
        // if (this.props.isIframeOpen) {
        this.setState({
            collapsed: true,
        });
        // }
        // else {
        //     this.setState({
        //         collapsed: false,
        //     });
        // }
    };

    public userRole(): void {
        const { userGroups } = this.props;
        const groups = ['admin', 'project-admin', 'user'];
        const filteredValue = groups.filter((value: any) => userGroups.groups?.includes(value));
        if (filteredValue.length > 0) {
            this.setState({
                filteredUserRole: filteredValue,
            });
        }
    }

    public componentDidMount(): void {
        const core = getCore();
        const { verifyAuthorized, history, location, triggerIFrame } = this.props;
        // configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

        // Logger configuration
        const userActivityCallback: (() => void)[] = [];
        window.addEventListener('click', () => {
            userActivityCallback.forEach((handler) => handler());
        });
        core.logger.configure(() => window.document.hasFocus, userActivityCallback);

        customWaViewHit(location.pathname, location.search, location.hash);
        history.listen((_location) => {
            customWaViewHit(_location.pathname, _location.search, _location.hash);
        });

        verifyAuthorized();
        triggerIFrame();

        const { name, version, engine, os } = platformInfo();

        if (showPlatformNotification()) {
            stopNotifications(false);
            Modal.warning({
                title: 'Unsupported platform detected',
                className: 'ltts-modal-unsupported-platform-warning',
                content: (
                    <>
                        <Row>
                            <Col>
                                <Text>
                                    {`The browser you are using is ${name} ${version} based on ${engine}.` +
                                        ' Annot AI was tested in the latest versions of Chrome and Firefox.' +
                                        ' We recommend to use Chrome (or another Chromium based browser)'}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text type='secondary'>{`The operating system is ${os}`}</Text>
                            </Col>
                        </Row>
                    </>
                ),
                onOk: () => stopNotifications(true),
            });
        } else if (showUnsupportedNotification()) {
            stopNotifications(false);
            Modal.warning({
                title: 'Unsupported features detected',
                className: 'ltts-modal-unsupported-features-warning',
                content: (
                    <Text>
                        {`${name} v${version} does not support API, which is used by Annot AI. `}
                        It is strongly recommended to update your browser.
                    </Text>
                ),
                onOk: () => stopNotifications(true),
            });
        }
    }

    public componentDidUpdate(): void {
        const {
            verifyAuthorized,
            isIframeOpen,
            customerIframe,
            loadFormats,
            loadAbout,
            loadUserAgreements,
            initPlugins,
            initModels,
            loadOrganizations,
            loadAuthActions,
            userInitialized,
            userFetching,
            organizationsFetching,
            organizationsInitialized,
            formatsInitialized,
            formatsFetching,
            aboutInitialized,
            aboutFetching,
            pluginsInitialized,
            pluginsFetching,
            modelsInitialized,
            modelsFetching,
            user,
            userAgreementsFetching,
            userAgreementsInitialized,
            authActionsFetching,
            authActionsInitialized,
            isModelPluginActive,
        } = this.props;

        this.showErrors();
        this.showMessages();

        if (!userInitialized && !userFetching) {
            verifyAuthorized();
            return;
        }

        if (!userAgreementsInitialized && !userAgreementsFetching) {
            loadUserAgreements();
            return;
        }

        if (!authActionsInitialized && !authActionsFetching) {
            loadAuthActions();
        }

        if (user === null || !user.isVerified) {
            return;
        }

        if (!organizationsInitialized && !organizationsFetching) {
            this.userRole();
            loadOrganizations();
        }

        if (!formatsInitialized && !formatsFetching) {
            loadFormats();
        }

        if (!aboutInitialized && !aboutFetching) {
            loadAbout();
        }

        if (isModelPluginActive && !modelsInitialized && !modelsFetching) {
            initModels();
        }

        if (!pluginsInitialized && !pluginsFetching) {
            initPlugins();
        }

        if (isIframeOpen) {
            this.toggleSideBar();
        }
        if (customerIframe) {
            this.toggleSideBar();
        }
    }

    private showMessages(): void {
        function showMessage(title: string): void {
            notification.info({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: 2,
            });
        }

        const { notifications, resetMessages } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.messages)) {
            for (const what of Object.keys((notifications as any).messages[where])) {
                const message = (notifications as any).messages[where][what];
                shown = shown || !!message;
                if (message) {
                    showMessage(message);
                }
            }
        }

        if (shown) {
            resetMessages();
        }
    }

    private showErrors(): void {
        function showError(title: string, _error: any, className?: string): void {
            const error = _error.toString();
            const dynamicProps = typeof className === 'undefined' ? {} : { className };
            notification.error({
                ...dynamicProps,
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: 2,
                description: error.length > 200 ? 'Open the Browser Console to get details' : error,
            });

            // eslint-disable-next-line no-console
            console.error(error);
        }

        const { notifications, resetErrors } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.errors)) {
            for (const what of Object.keys((notifications as any).errors[where])) {
                const error = (notifications as any).errors[where][what];
                shown = shown || !!error;
                if (error) {
                    showError(error.message, error.reason, error.className);
                }
            }
        }

        if (shown) {
            resetErrors();
        }
    }

    applyLayoutMargin() {
        return this.state.collapsed ? { marginLeft: 80 } : { marginLeft: 200 };
    }

    // Where you go depends on your UR7
    public render(): JSX.Element {
        const {
            userInitialized,
            aboutInitialized,
            pluginsInitialized,
            formatsInitialized,
            modelsInitialized,
            organizationsInitialized,
            switchShortcutsDialog,
            switchSettingsDialog,
            user,
            keyMap,
            location,
            isModelPluginActive,
        } = this.props;

        const readyForRender =
            (userInitialized && (user === null || !user.isVerified)) ||
            (userInitialized &&
                formatsInitialized &&
                pluginsInitialized &&
                aboutInitialized &&
                organizationsInitialized &&
                (!isModelPluginActive || modelsInitialized));

        const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
            SWITCH_SETTINGS: keyMap.SWITCH_SETTINGS,
        };

        const handlers = {
            SWITCH_SHORTCUTS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();

                switchShortcutsDialog();
            },
            SWITCH_SETTINGS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();

                switchSettingsDialog();
            },
        };
        if (user && user.acceptanceVerified) {
            return (
                <GlobalErrorBoundary>
                    <Switch>
                        <Route exact path='/auth/termsandconditions' component={AgreementConfirmationPage} />
                        {/* )} */}
                        <Redirect to='/auth/termsandconditions' />
                    </Switch>
                </GlobalErrorBoundary>
            );
        }
        if (readyForRender) {
            if (user && user.isVerified) {
                return (
                    <GlobalErrorBoundary>
                        <Layout hasSider>
                            <Sider
                                id='sider'
                                style={{
                                    width: '75px',
                                    height: '100vh',
                                    position: 'fixed',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                }}
                                className={'ltts-slider ' + (this.props.isIframeOpen ? 'ltts-frame' : '')}
                                collapsible
                                collapsed={this.state.collapsed}
                                onCollapse={this.toggleCollapsed}
                            >
                                <SideBar
                                    filteredArray={this.state.filteredUserRole}
                                    collapsed={this.state.collapsed}
                                    toggleCollapsed={this.toggleCollapsed}
                                    isIframeOpen={this.props.isIframeOpen}
                                />
                            </Sider>
                            <Layout id='layout-section' style={this.applyLayoutMargin()}>
                                <HeaderComponent collapsed={this.state.collapsed} />
                                <Content
                                    id={
                                        this.props.isIframeOpen || this.props.customerIframe
                                            ? 'content-layout-withIframe'
                                            : 'content-layout'
                                    }
                                >
                                    <ShortcutsDialog />
                                    <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                                        <Switch>
                                            {/* <Route exact path='/projects' component={ProjectsPageComponent} /> */}
                                            <Route
                                                exact
                                                path='/projects/create'
                                                component={CreateProjectPageComponent}
                                            />
                                            <Route exact path='/projects/:id' component={ProjectPageComponent} />
                                            <Route exact path='/tasks' component={TasksPageContainer} />
                                            <Route exact path='/tasks/create' component={CreateTaskPageContainer} />
                                            <Route exact path='/tasks/:id' component={TaskPageContainer} />
                                            <Route
                                                exact
                                                path='/tasks/:tid/jobs/:jid'
                                                component={AnnotationPageContainer}
                                            />
                                            <Route exact path='/jobs' component={JobsPageComponent} />
                                            <Route exact path='/cloudstorages' component={CloudStoragesPageComponent} />
                                            <Route exact path='/upload-files/:id' component={FileManager} />
                                            <Route exact path='/project-dataset' component={ProjectDatasetComponent} />
                                            <Route
                                                exact
                                                path='/cloudstorages/create'
                                                component={CreateCloudStoragePageComponent}
                                            />
                                            <Route
                                                exact
                                                path='/cloudstorages/update/:id'
                                                component={UpdateCloudStoragePageComponent}
                                            />
                                            {/* <Route
                                            exact
                                            path='/organizations/create'
                                            component={CreateOrganizationComponent}
                                        /> */}
                                            <Route
                                                exact
                                                path='/organizations-create'
                                                component={CreateOrganizationPageComponent}
                                            />
                                            <Route exact path='/organization' component={OrganizationPage} />
                                            {isModelPluginActive && (
                                                <Route exact path='/models' component={ModelsPageContainer} />
                                            )}

                                            <Route
                                                exact
                                                path='/project-management-create'
                                                component={ProjectCreationStepper}
                                            />
                                            <Route
                                                exact
                                                path='/project-management-edit/:id'
                                                component={ProjectCreationStepper}
                                            />
                                            <Route exact path='/projects' component={Dashboard} />
                                            <Route exact path='/appsstage' component={AppsPageComponent} />
                                            <Route exact path='/userlist' component={UserListComponent} />
                                            <Route exact path='/reports' component={ReportsComponent} />
                                            <Route exact path='/overall-status' component={OverallStatusComponent} />
                                            <Route exact path='/customer-review' component={CustomerReviewComponent} />
                                            <Route exact path='/bmw-reports' component={BMWReportsComponent} />
                                            {/* <Route exact path='/projects/:id' component={Dashboard} /> */}
                                            <Route exact path='/edit-project/:id' component={editProject} />
                                            <Route exact path='/projectApps/:id' component={ProjectApps} />
                                            <Route exact path='/author-project/:id' component={projectAdmin} />
                                            {this.state.filteredUserRole[0] === 'user' ? (
                                                <Redirect to='/appsstage' />
                                            ) : (
                                                <Redirect to='/projects' />
                                            )}
                                        </Switch>
                                    </GlobalHotKeys>
                                    {/* eslint-disable-next-line */}
                                    <ExportDatasetModal />
                                    {/* eslint-disable-next-line */}
                                    <a id='downloadAnchor' style={{ display: 'none' }} download />
                                </Content>
                                {!this.props.isIframeOpen || (!this.props.customerIframe && <FooterComponent />)}
                            </Layout>
                        </Layout>
                    </GlobalErrorBoundary>
                );
            }

            return (
                <GlobalErrorBoundary>
                    <Switch>
                        <Route exact path='/auth/register' component={RegisterPageContainer} />
                        <Route exact path='/auth/login' component={LoginPageContainer} />
                        <Route
                            exact
                            path='/auth/login-with-token/:sessionId/:token'
                            component={LoginWithTokenComponent}
                        />
                        <Route exact path='/auth/password/reset' component={ResetPasswordPageComponent} />
                        <Route
                            exact
                            path='/auth/password/reset/confirm'
                            component={ResetPasswordPageConfirmComponent}
                        />

                        <Route exact path='/auth/email-confirmation' component={EmailConfirmationPage} />

                        {/* <Redirect
                            to={location.pathname.length > 1 ? `/auth/login/?next=${location.pathname}` : '/auth/login'}
                        />
                        /> */}
                        <Redirect to='/auth/login' />
                    </Switch>
                </GlobalErrorBoundary>
            );
        }

        return <Spinner />;
    }
}

export default withRouter(CVATApplication);
