// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Menu from 'antd/lib/menu';
import { useHistory } from 'react-router';
import { editMode, initialiaseStepperCount } from 'actions/projects-mgnt-actions';
import {  useDispatch } from 'react-redux';

interface Props {
    projectInstance: any;
}

export default function ProjectActionsMenuComponent(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { projectInstance } = props;
    const history = useHistory();

    const showModal = () => {
        history.push({
            pathname: `/upload-files/${projectInstance.id}`,
            state: projectInstance.id,
        });
    };

    const showProjectDataset = () => {
        history.push({
            pathname: '/project-dataset',
            state: projectInstance.id,
        });
    };
    const showEdit = () => {
        dispatch(editMode());
        dispatch(initialiaseStepperCount());
        history.push({
            pathname: `/project-management-edit/${projectInstance.id}`,
            state: JSON.stringify({
                id: projectInstance.id,
                project_config: projectInstance.project_config,
                name: projectInstance.name,
                description: projectInstance.description,
                start_date: projectInstance.start_date,
                project_type: projectInstance.project_type,
                batch_name: projectInstance.batch_name,
                recording_name: projectInstance.recording_name,
            }),
        });
    };

    return (
        <>
            <Menu selectable={false} className='ltts-projects-actions-menu' mode='inline'>
                <Menu.Item key='View' className='project-menu-dropdown'>View</Menu.Item>
                <Menu.Item className='project-menu-dropdown'
                    key='Edit'
                    onClick={() => {
                        showEdit();
                    }}
                >
                    Edit
                </Menu.Item>
                <Menu.Item className='project-menu-dropdown'
                    key='project-apps'
                    onClick={()=>{
                        history.push({
                            pathname: `/projectApps/${projectInstance.id}`,
                            state: projectInstance.id,
                        });
                    }}
                >
                    Project Apps
                </Menu.Item>
                <Menu.Item className='project-menu-dropdown'
                    key='project'
                    onClick={()=>{
                        history.push({
                            pathname: `/author-project/${projectInstance.id}`,
                            state: projectInstance.id,
                        });
                    }}
                >
                    Project Admin
                </Menu.Item>
                <Menu.Item className='project-menu-dropdown'
                    disabled={projectInstance.project_creation_status !== 'completed'}
                    key='Upload Data'
                    onClick={() => showModal()}
                >
                    Upload Data
                </Menu.Item>
                <Menu.Item key='Project Data' className='project-menu-dropdown project-data-menu'  onClick={() => showProjectDataset()}>
                    Project Data
                </Menu.Item>
            </Menu>
        </>
    );
}
