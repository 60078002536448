// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import { AnyAction } from 'redux';
import { ProjectsSagaActionTypes } from 'actions/projects-mgnt-actions';
import { BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActionTypes } from 'actions/auth-actions';
import { Project, ProjectsState } from './interfaces';

const defaultState: ProjectsState = {
    gridView: true,
    initialized: false,
    fetching: false,
    editFetching: true,
    count: 0,
    current: [],
    project_list: [],
    projectExportData: [],
    projectDataeditable: {name:'',description:'',start_date:'',project_type:null, project_config:null},
    editMode:false,
    projectExportDataFormat: [],
    gettingQuery: {
        page: 1,
        id: null,
        search: null,
        owner: null,
        assignee: null,
        name: null,
        status: null,
    },
    tasksGettingQuery: {
        page: 1,
        id: null,
        search: null,
        owner: null,
        assignee: null,
        name: null,
        status: null,
        mode: null,
        projectId: null,
        ordering: 'subset',
    },
    activities: {
        deletes: {},
        stepperCount: 0,
        creates: {
            id: null,
            error: '',
            p2p_mapping: null,
            project_type: null,
            name: '',
            description: '',
            start_date: '',
            project_config: null,
            project_creation_status: '',
            // batch_name: '',
            // recording_name: '',
        },
        backups: {},
    },
    restoring: false,
};

export default (state: ProjectsState = defaultState, action: AnyAction): ProjectsState => {
    switch (action.type) {
        case ProjectsSagaActionTypes.GRID_LIST_VIEW:
            return {
                ...state,
                gridView: action.payload,
            };
        case ProjectsSagaActionTypes.GET_PROJECTS_TYPE:

            return {
                ...state,
                fetching: true,
            };
        case ProjectsSagaActionTypes.GET_PROJECTS_TYPE_SUCCESS:
            return {
                ...state,
                fetching: false,
                project_list: action.payload.project_list,
            };
        case ProjectsSagaActionTypes.GET_PROJECTS_TYPE_FAILED: {
            return {
                ...state,
                fetching: true,
            };
        }
        // code added by raju
        case ProjectsSagaActionTypes.UPDATE_PROJECTS_GETTING_QUERY:
            return {
                ...state,
                gettingQuery: {
                    ...defaultState.gettingQuery,
                    ...action.payload.query,
                },
                // tasksGettingQuery: {
                //     ...defaultState.tasksGettingQuery,
                //     ...action.payload.tasksQuery,
                // },
            };
        case ProjectsSagaActionTypes.GET_PROJECTS:
            return {
                ...state,
                initialized: false,
                fetching: true,
                count: 0,
                current: [],
            };
        case ProjectsSagaActionTypes.GET_PROJECTS_SUCCESS: {
            const combinedWithPreviews = action.payload.array.map(
                (project: any, index: number): Project => ({
                    instance: project,
                    preview: action.payload.previews[index],
                }),
            );

            return {
                ...state,
                initialized: true,
                fetching: false,
                count: action.payload.count,
                current: combinedWithPreviews,
            };
        }
        case ProjectsSagaActionTypes.GET_PROJECTS_FAILED: {
            return {
                ...state,
                initialized: true,
                fetching: false,
            };
        }
        // code ended by raju
        case ProjectsSagaActionTypes.CREATE_PROJECT: {
            return {
                ...state,
                fetching: true,
                activities: {
                    ...state.activities,
                    creates: {
                        id: null,
                        error: '',
                        name: '',
                        p2p_mapping: null,
                        project_config: null,
                        project_type: null,
                        project_creation_status: '',
                        description: '',
                        start_date: '',
                        // batch_name: '',
                        // recording_name: '',
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_FAILED: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        error: action.payload.error.toString(),
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_SUCCESS: {
            return {
                ...state,
                fetching: false,
                activities: {
                    ...state.activities,
                    stepperCount: 1,
                    creates: {
                        id: action.payload.projectId,
                        project_type: action.payload.project_type,
                        name: action.payload.name,
                        description: action.payload.description,
                        start_date: action.payload.start_date,
                        // batch_name:  action.payload.batch_name,
                        // recording_name:  action.payload.recording_name,
                        project_config: null,
                        p2p_mapping: null,
                        error: '',
                        project_creation_status: '',
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS: {
            return {
                ...state,
                fetching: true,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        id: null,
                        project_type: null,
                        project_config: null,
                        name: '',
                        p2p_mapping: null,
                        error: '',
                        project_creation_status: '',
                        // batch_name: '',
                        // recording_name: '',
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS_FAILED: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        error: action.payload.error.toString(),
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS_SUCCESS: {
            return {
                ...state,
                fetching: false,
                activities: {
                    ...state.activities,
                    stepperCount: 2,
                    creates: {
                        ...state.activities.creates,
                        name: action.payload.name,
                        description: action.payload.description,
                        id: action.payload.projectId,
                        project_type: action.payload.project_type,
                        project_config: action.payload.project_config,
                        // batch_name:  action.payload.batch_name,
                        // recording_name:  action.payload.recording_name,
                        error: '',

                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW: {
            return {
                ...state,
                fetching: true,
            }
        }
        case ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS: {
            return {
                ...state,
                editFetching: true,
            }
        }
        case ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS_SUCCESS: {
            return {
                ...state,
                editFetching: false,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        name: action.payload.name,
                        description: action.payload.description,
                        id: action.payload.projectId,
                        project_type: action.payload.project_type,
                        project_config: action.payload.project_config,
                        // batch_name:  action.payload.batch_name,
                        // recording_name:  action.payload.recording_name,
                        error: '',
                    },
                },
            };
        }

        case ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW_SUCCESS: {
            return {
                ...state,
                fetching: false,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        project_creation_status: action.payload.project_creation_status

                    },
                },
            };
        }

        case ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN_SUCCESS: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    stepperCount: 2,
                    creates: {
                        ...state.activities.creates,
                        id: action.payload.projectValues.getProjectDetails.id,
                        name: action.payload.projectValues.getProjectDetails.name,
                        project_type: action.payload.projectValues.getProjectDetails.project_type,
                        project_config: action.payload.projectValues.getProjectDetails.project_config,
                        p2p_mapping: action.payload.projectValues.res.p2p_mapping,
                        error: '',

                    },
                },
            }
        }

        case ProjectsSagaActionTypes.CREATE_UPLOAD_FILES: {
            return {
                ...state,
                fetching: true
            }
        }

        case ProjectsSagaActionTypes.CREATE_UPLOAD_FILES_SUCCESS: {
            return {
                ...state,
                fetching: false
            }
        }

        case ProjectsSagaActionTypes.DEFAULT_STEPPER_COUNT: {
            return {...defaultState};
        }

        case ProjectsSagaActionTypes.EDIT_MODE: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    editMode:true
                },
            };
        }

        case ProjectsSagaActionTypes.INCREASE_STEPPER_COUNT: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    stepperCount: ++state.activities.stepperCount,
                },
            };
        }

        case ProjectsSagaActionTypes.DECREASE_STEPPER_COUNT: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    stepperCount: --state.activities.stepperCount,
                },
            };
        }

        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA:

            return {
                ...state,
                fetching: true,
            };
        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_SUCCESS:
            return {
                ...state,
                fetching: false,
                projectExportData: action.exportData,
                activities: {
                    ...state.activities,
                    creates: {
                        ...state.activities.creates,
                        project_type: action.exportData,
                    }
                }
            };

            case ProjectsSagaActionTypes.GET_PROJECT_DATA_SUCCESS:
            return {
                 ...state,
                 projectDataeditable: action.projectData,
            };

        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FAILED: {
            return {
                ...state,
                fetching: true,
            };
        }


        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FORMAT:

            return {
                ...state,
                fetching: true,
            };
        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FORMAT_SUCCESS:
            return {
                ...state,
                fetching: false,
                projectExportDataFormat: action.exportDataFormat,

            };
        case ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FORMAT_FAILED: {
            return {
                ...state,
                fetching: true,
            };
        }
        case ProjectsSagaActionTypes.GET_INITIALISED_STATE: {
            return { ...defaultState };
        }

        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        default:
            return state;
    }
};
