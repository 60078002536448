import Icon from '@ant-design/icons';
import { Button, Table, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { ReportDownloadIcon } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { DownOutlined } from '@ant-design/icons';
import { UpOutlined } from '@ant-design/icons';
import SearchBar from 'components/dashboard/custom-search';
import { getUserWiseTimeReport, getUsersTotalTime } from 'actions/reports-action';
import moment from 'moment';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';

const Timeontool = () => {
    let today = new Date();
    let dates = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
    const searchKeys = ['UserName', 'date'];
    const [expended, setExpended] = useState();
    const [Stardate, setStardate] = useState('');
    const [Enddate, setEnddate] = useState('');
    const dispatch = useDispatch();
    const expend = (index: number, data: any) => {
        if (expended === index) {
            setExpended(undefined);
        } else {
            setExpended(index);
            dispatch(getUserWiseTimeReport(data?.Date, data?.user_id));
        }
    };
    const columns = [
        {
            title: 'SerialNo',
            dataIndex: 'SerialNo',
            key: 'SerialNo',
        },
        {
            title: 'UserName',
            dataIndex: 'UserName',
            sorter: (a, b) => a.UserName.localeCompare(b.UserName),
            key: 'UserName',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'TimeSpent',
            dataIndex: 'TimeSpent',
            key: 'TimeSpent',
        },
        {
            title: 'Action',
            key: 'operation',
            render: (data: any) => {
                return (
                    <a onClick={() => expend(data?.SerialNo, data)}>
                        {data.SerialNo === expended ? <UpOutlined /> : <DownOutlined />}
                    </a>
                );
            },
        },
    ];
    useEffect(() => {
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        setStardate(date);
        setEnddate(date);
        dispatch(getUsersTotalTime(date, date));
    }, [dispatch]);
    const expandedRowRender = () => {
        const columns = [
            // {
            //     title: 'SerialNo',
            //     dataIndex: 'SerialNo',
            //     key: 'SerialNo',
            // },
            { title: 'ProjectName', dataIndex: 'ProjectName', key: 'ProjectName' },
            { title: 'TimeSpent', dataIndex: 'TimeSpent', key: 'TimeSpent' },
            {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                // render: () => (
                //     <span>
                //         <Badge status='success' />
                //     </span>
                // ),
            },
            { title: 'StageName', dataIndex: 'StageName', key: 'StageName' },
            { title: 'Updated_at', dataIndex: 'Updated_at', key: 'Updated_at' },
            { title: 'JobId', dataIndex: 'JobId', key: 'JobId' },
            { title: 'TaskId', dataIndex: 'TaskId', key: 'TaskId' },
            { title: 'WorkflowId', dataIndex: 'WorkflowId', key: 'WorkflowId' },
            { title: 'BrowserType', dataIndex: 'BrowserType', key: 'BrowserType' },
        ];
        let serialNo = 0;
        let dataSource = usertimeReport.map((user: any) => {
            serialNo += 1;
            return {
                // SerialNo: serialNo,
                ProjectName: user.project_name,
                UserName: user.username,
                TimeSpent: user.time,
                Status: user.status,
                StageName: user.stage_name,
                Updated_at: user.updated_at,
                JobId: user?.data_info?.job_id,
                TaskId: user?.data_info?.task_id,
                WorkflowId: user?.workflow_id,
                BrowserType: user?.browser_type,
            };
        });
        return (
            <Table
                locale={{ emptyText: 'No data Available' }}
                className='table-layout'
                columns={columns}
                dataSource={dataSource}
                size={'small'}
                scroll={{ x: 'calc(600px + 50%)', y: 250 }}
            />
        );
    };
    let usertotaltimeReport, usertimeReport: any;
    usertimeReport = useSelector((state: CombinedState) => state.reports.userTimeReports);
    usertotaltimeReport = useSelector((state: CombinedState) => state.reports.userTotalTime);
    let serialNo = 0;
    let dataSource = Array.isArray(usertotaltimeReport)
        ? usertotaltimeReport?.map((user) => {
              serialNo += 1;
              return {
                  SerialNo: serialNo,
                  UserName: user.username,
                  Date: user.date,
                  TimeSpent: user.total_time,
                  user_id: user.user_id,
              };
          })
        : [];
    const dateFormat = 'YYYY/MM/DD';
    let [filteredResults, setFilteredResults] = useState(dataSource);
    const onTableRowExpand = (expanded: any, record: any) => {
        const keys = [];
        if (expanded) {
            keys.push(record.SerialNo);
        }
        setExpended(keys);
    };
    const searchApps = (e: any) => {
        const searchValue = e.target.value;
        if (searchValue !== '') {
            const filteredData: any = dataSource.filter((item: any) =>
                searchKeys.some((key) => item[key]?.toLowerCase().includes(searchValue.toLowerCase())),
            );
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(dataSource);
        }
        let timer: any;
        if (e.type === 'click') {
            e.preventDefault();
            timer = setTimeout(() => {
                setFilteredResults(dataSource);
            }, 1000);
        }
        if (filteredResults.length == 0) {
            filteredResults = [];
        }
    };
    let startDate: any, endDate: any;
    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        startDate = dateString;
        setStardate(startDate);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        endDate = dateString;
        setEnddate(endDate);
    };
    const GetTime = () => {
        setFilteredResults([]);
        if (Enddate || Stardate) {
            dispatch(getUsersTotalTime(Stardate, Enddate));
        }
    };
    return (
        <>
            <Row className='user-title-row' gutter={[24, 32]}>
                <Col span={12}>
                    <span className='day-report-date-span'>
                        <DatePicker
                        disabledDate={(current) => {
                            let createdDate = moment(new Date()).utc().format();
                            let expirationDate = moment(createdDate).add(1, 'd').format("YYYY-MM-DD");
                            return current && current > moment(expirationDate, "YYYY-MM-DD");
                          }}
                            defaultValue={moment(dates, dateFormat)}
                            format={'YYYY-MM-DD'}
                            placeholder={'StartDate'}
                            className='day-report-date-button'
                            onChange={onFromChange}
                        />
                        <DatePicker
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day');
                          }}
                            placeholder={'EndDate'}
                            onChange={onToChange}
                            defaultValue={moment(dates, dateFormat)}
                            format={'YYYY-MM-DD'}
                        />
                        <Button className='day-report-filter' onClick={GetTime}>
                            GetTime
                        </Button>
                    </span>
                </Col>
                <Col span={6}>
                    <SearchBar onSearchChange={searchApps} />
                </Col>
                <Col span={6}>
                    <span className='download'>
                        {dataSource?.length > 0 ? (
                            <ExcelFile
                                filename='TooLontime'
                                element={
                                    <Button className='report-download'>
                                        <Icon component={ReportDownloadIcon} />
                                        Download
                                    </Button>
                                }
                            >
                                <ExcelSheet data={dataSource} name='TimeReports'>
                                    <ExcelColumn label='SerialNo' value='SerialNo' />
                                    <ExcelColumn label='UserName' value='UserName' />
                                    <ExcelColumn label='Date' value='Date' />
                                    <ExcelColumn label='TimeSpent' value='TimeSpent' />
                                </ExcelSheet>
                            </ExcelFile>
                        ) : null}
                    </span>
                </Col>
            </Row>
            <Table
                columns={columns}
                locale={{ emptyText: 'TOT not Available on this date' }}
                dataSource={filteredResults.length > 0 ? filteredResults : dataSource}
                expandedRowKeys={[expended]}
                rowKey='SerialNo'
                expandable={{
                    rowExpandable: (record) => record.SerialNo,
                    expandedRowRender,
                    expandIcon: () => <></>,
                }}
                scroll={{ x: 'calc(600px + 50%)', y: 350 }}
                onExpand={onTableRowExpand}
            />
        </>
    );
};

export default Timeontool;
