import React, { useState, useEffect, useRef } from 'react';
import { Modal, Radio, notification } from 'antd';
import ReactFlow, { ReactFlowProvider, useNodesState, useEdgesState, Controls, Background } from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { dispatchRejectStages, switchRejectDialog, SendAnnotatorTime } from 'actions/apps-stages-actions';

const initialNodes: any = [];

interface Props {
    visible: boolean;
    onSubmit(): void;
}

export default function ProjectStagesRejectionComponent(props: Props): JSX.Element {
    const { visible, onSubmit } = props;
    const dispatch = useDispatch();
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [rejectStage, setRejectStage] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const { projectMetaData, previousStages, rejectMessage } = useSelector((state: CombinedState) => state.appsStages);
    const { viewTaskDetails } = useSelector((state: CombinedState) => state.reports);
    const userRole = useSelector((state: CombinedState) => state.auth.user);
    useEffect(() => {
        if (Object.keys(previousStages).length > 0) {
            const { previous_stages } = previousStages;
            const nodeWidth = 80;
            const nodeHeight = 80;
            let yValue = 150;
            const style = { width: nodeWidth, height: nodeHeight, fontSize: 10, color: 'black' };
            if (previous_stages?.length > 0) {
                const sortedStages = previous_stages.sort(
                    (stage1: any, stage2: any) => parseFloat(stage1.project_app_id) - parseFloat(stage2.project_app_id),
                );
                sortedStages.forEach((app: any, index: number) => {
                    yValue = yValue + 100;
                    const newNode = {
                        id: sortedStages[index].project_app_id.toString(),
                        type: 'input',
                        className: 'stages-node-list',
                        app_alias_name: app.user_id,
                        app_seq_id: app.project_app_id,
                        data: {
                            label: (
                                <>
                                    <div className='previous-stages-align'>
                                        <div>
                                            <Radio.Group
                                                value={selectedValue}
                                                onChange={(e) => handleChange(e, sortedStages[index])}
                                            >
                                                <Radio
                                                    className='stages-radio-input'
                                                    checked={sortedStages[index].project_app_id === selectedValue}
                                                    value={sortedStages[index].project_app_id}
                                                />
                                            </Radio.Group>
                                        </div>
                                        <div className='stages-content'>
                                            <div>
                                                <strong>STAGE-{index + 1}</strong>
                                            </div>
                                            <div>{sortedStages[index].app_alias_name}</div>
                                            <div>{sortedStages[index].username}</div>
                                        </div>
                                    </div>
                                </>
                            ),
                        },
                        position: { x: 0, y: yValue },
                    };
                    setNodes((nds: any) => nds.concat(newNode));
                });
            }
        }
    }, [previousStages, setNodes, selectedValue]);

    useEffect(() => {
        if (rejectMessage !== '') {
            setNodes([]);
            onSubmit();
            setRejectStage(null);
            setSelectedValue(null);
        }
    }, [rejectMessage]);

    const handleChange = (e: any, app: any) => {
        setSelectedValue(e.target.value);
        const stages = {
            workflow_id: projectMetaData?.workflow_id || viewTaskDetails?.workflow_id,
            rejected_to_app_id: app.project_app_id,
            rejected_to_user_id: app.user_id,
            status: 'rejected',
        };
        setRejectStage(stages);
    };

    const handleOk = () => {
        // let today = new Date();
        // let start_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        // let Timertab1 = {
        //     project_id: projectMetaData?.project_id,
        //     workflow_id: projectMetaData?.workflow_id,
        //     user_id: userRole?.id,
        //     start_time: '00:00:00',
        //     end_time: start_time,
        //     browser_type: 'Chrome',
        //     session_id: '123',
        // };
        if (!rejectStage) {
            notification.error({
                message: 'Please select stage to reject',
            });
        } else {
            sessionStorage.removeItem("isopen_status");
            localStorage.removeItem('workflow_details');
            // setTimeout(() => {
                dispatch(dispatchRejectStages(rejectStage));
            // }, 3000);
            // dispatch(SendAnnotatorTime(Timertab1));
        }
    };

    const handleClose = () => {
        dispatch(switchRejectDialog(false));
        setSelectedValue(null);
        let today = new Date();
        let nAgt = navigator.userAgent;
        let browserName  = navigator.appName;
        let verOffset;
        // In Opera, the true version is after "OPR" or after "Version"
        if ((verOffset=nAgt.indexOf("OPR"))!=-1) {
         browserName = "Opera";
         if ((verOffset=nAgt.indexOf("Version"))!=-1);
        }
        // In MS Edge, the true version is after "Edg" in userAgent
        else if ((verOffset=nAgt.indexOf("Edg"))!=-1) {
         browserName = "Microsoft Edge";
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
         browserName = "Microsoft Internet Explorer";
        }
        // In Chrome, the true version is after "Chrome";
        else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
         browserName = "Chrome";
        }
        // In Safari, the true version is after "Safari" or after "Version";
        else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
         browserName = "Safari";
         if ((verOffset=nAgt.indexOf("Version"))!=-1);
        }
        // In Firefox, the true version is after "Firefox";
        else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
         browserName = "Firefox";
        }
        let start_time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        let Timertab1 = {
            project_id: projectMetaData?.project_id,
            workflow_id: projectMetaData?.workflow_id,
            user_id: userRole?.id,
            start_time: start_time,
            end_time: '00:00:00',
            browser_type: browserName,
            // session_id: '123',
            popup_flag: "False",
        };
        dispatch(SendAnnotatorTime(Timertab1));
    };

    return (
        <>
            <Modal
                onCancel={handleClose}
                onOk={handleOk}
                cancelButtonProps={{
                    style: {
                        display: 'none',
                    },
                }}
                className='reject-modal-align'
                title='Which stage would you like to Re-Assign current task to?'
                visible={visible}
                centered
                width={800}
            >
                <div className='dndflow'>
                    <ReactFlowProvider>
                        <div className='reactflow-wrapper' ref={reactFlowWrapper}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onInit={setReactFlowInstance}
                                defaultZoom={1.5}
                                style={{ background: '#282828' }}
                                fitView
                            >
                                <Controls />
                                <Background />
                            </ReactFlow>
                        </div>
                    </ReactFlowProvider>
                </div>
            </Modal>
        </>
    );
}
