import React, { useEffect, useState } from 'react';
import { Tabs, Table, Row, Col, Dropdown, Menu, Checkbox, Space } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import Icon, { MoreOutlined } from '@ant-design/icons';
import { ActiveStatusLogo, OfflineStatusLogo, ProjectSortIcon, UserFilterLogo, UserLogo } from 'icons';
import Text from 'antd/lib/typography/Text';
import './user-list.scss';
import { fetchUsers } from 'actions/apps-stages-actions';
import SearchBar from 'components/dashboard/custom-search';

const { TabPane } = Tabs;

export default function UserListComponent(): JSX.Element {
    const [size, setSize] = useState<SizeType>('small');
    const query = useSelector((state: CombinedState) => state.projects.gettingQuery);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUsers());
    }, []);
    const userList = useSelector((state: CombinedState) => state.appsStages.users);
    const onChange = (key: string) => {};

    let dataSource = userList.map((user) => ({
        key: user.id,
        name: user.username,
        Designation: '-',
        Email: user.email,
        MobileNo: 'xxxxxxxxxx',
        Status: user.is_active === true ? 'Active' : 'Inactive',
        Action: <MoreOutlined />,
    }));
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Icon component={UserLogo} />
                        <span className='user-logo-icon-text'>{text}</span>
                    </div>
                );
            },
        },
        {
            title: 'Designation',
            dataIndex: 'Designation',
            key: 'Designation',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'MobileNo',
            dataIndex: 'MobileNo',
            key: 'MobileNo',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text, record) => {
                return (
                    <div>
                        <Icon
                            className='offline-status-logo'
                            component={text === 'Active' ? ActiveStatusLogo : OfflineStatusLogo}
                        />
                        <span className='status-text'>{text}</span>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
        },
    ];

    const dimensions = {
        md: 17,
        lg: 18,
        xxl: 19,
        xs: 20,
    };

    const searchKeys = ['name', 'Email', 'Status'];
    let [filteredResults, setFilteredResults] = useState(dataSource);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(12);
    const indexOfLastApps = currentPage * usersPerPage;
    const indexOfFirstApps = indexOfLastApps - usersPerPage;
    const changePage = (pageNumber: any) => setCurrentPage(pageNumber);
    const currentProjectApps = filteredResults.slice(indexOfFirstApps, indexOfLastApps);

    let timer: any;
    const searchApps = (e: any) => {
        const searchValue = e.target.value;
        if (searchValue !== '') {
            const filteredData: any = dataSource.filter((item) =>
                searchKeys.some((key) => item[key].toLowerCase().includes(searchValue.toLowerCase())),
            );
            setCurrentPage(1);
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(dataSource);
        }

        if (e.type === 'click') {
            e.preventDefault();
            timer = setTimeout(() => {
                setFilteredResults(dataSource);
            }, 1000);
        }
        if (filteredResults.length == 0) {
            filteredResults = [];
        }
    };

    const menu = (
        <Menu className='filter-menu' multiple={true}>
            <Menu.Item key='1'>
                <Checkbox value='Image' onChange={(e) => showUpdateModal(e)}>
                    Image
                </Checkbox>
            </Menu.Item>
            <Menu.Item key='2'>
                <Checkbox value='Video' onChange={(e) => showUpdateModal(e)}>
                    Video
                </Checkbox>
            </Menu.Item>
            <Menu.Item key='3'>
                <Checkbox value='LiDAR' onChange={(e) => showUpdateModal(e)}>
                    LiDAR
                </Checkbox>
            </Menu.Item>
            <Menu.Item key='4'>
                <Checkbox value='Audio' onChange={(e) => showUpdateModal(e)}>
                    Audio
                </Checkbox>
            </Menu.Item>
            <Space style={{ width: '100%', padding: '10px' }}></Space>
        </Menu>
    );

    return (
        <>
            <Row className='user-title-row'>
                <Col span={3} className='user-list-title-text'>
                    Userlist
                </Col>
                <Col span={15}></Col>
                <Col span={6}>
                    <SearchBar onSearchChange={searchApps} />
                </Col>
            </Row>
            <Row justify='start' align='middle' className='ltts-subheader'>
                <Col {...dimensions}>
                    <Dropdown overlay={menu} className='ltts-header-menu-dropdown-filter' trigger={['click']}>
                        <span>
                            <Icon className='user-filter-icon' component={UserFilterLogo} />
                            <Text type='secondary' className='ltts-menu-dropdown-text'>
                                User
                            </Text>
                        </span>
                    </Dropdown>
                    <Dropdown overlay={menu} className='ltts-header-menu-dropdown-sort' trigger={['click']}>
                        <span>
                            <Icon className='ltts-sort-icon' component={ProjectSortIcon} />
                            <Text type='secondary' className='ltts-menu-dropdown-text'>
                                Group
                            </Text>
                        </span>
                    </Dropdown>
                    <button type='button' className='grid-view-button'>
                        <Text type='secondary' className='ltts-menu-dropdown-text'>
                            ClearAll
                        </Text>
                    </button>
                </Col>
            </Row>
            <div className='user-tab'>
                <Tabs onChange={onChange} type='card'>
                    <TabPane tab='User' key='1'>
                        <Table
                            dataSource={filteredResults.length > 0 ? filteredResults : dataSource}
                            columns={columns}
                        />
                    </TabPane>
                    <TabPane tab='Groups' key='2'>
                        Content of Tab Pane 2
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}