import './overall-status-styles.scss';
import Icon from '@ant-design/icons';
import { Button, Select, DatePicker, Table, DatePickerProps, Divider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import type { TablePaginationConfig } from 'antd/es/table';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import notification from 'antd/lib/notification';

import {
    SessionDownloadError,
    SessionSuccessIcon,
    SessionFailedIcon,
    SessionInProgressIcon,
    SessionExceptionIcon,
    SessionCorrectedIcon,
} from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSessionWiseData,
    getSessionStatusData,
    submitCorrectedSessions,
    getSessionTicketData,
    downloadErrorFile,
} from 'actions/overall-status-actions';
import { CombinedState } from 'reducers/interfaces';
import CVATTooltip from 'components/common/ltts-tooltip';

const SessionTable: React.FC = () => {
    const [selectedTicketType, setSelectedTicketType] = useState<string>('');
    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const ticketTypes: string[] = [
        // 'Select Ticket Type',
        'T08_meta',
        'T01_ns_2D_and_3D',
        'T01_1fps_2D_and_3D',
        'T01_seq_2D_and_3D',
    ];
    const statusTypes: string[] = ['New', 'Annotated', 'In Progress', 'Passed', 'Error', 'Exception', 'Corrected'];
    const dateFormat = 'DD/MM/YYYY';
    let today = new Date();
    let dates = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [correctedRows, setCorrectedRows] = useState<any[]>([]);

    let sessionData = useSelector((state: CombinedState) => state?.exportAutomation?.sessionData);
    let sessionStatusData = useSelector((state: CombinedState) => state?.exportAutomation?.statusData);
    let sessionTicketData = useSelector((state: CombinedState) => state?.exportAutomation?.ticketData);
    let sessionTriggeredData = useSelector((state: CombinedState) => state?.exportAutomation?.triggeredData);

    const dispatch = useDispatch();

    const fetchSessionData = () => {
        setSelectedStatus('');
        setSelectedTicketType('');
        setCorrectedRows([]);
        dispatch(getSessionWiseData());
        setFilteredResults(sessionDataMapping(sessionData));
    };

    const handleSubmit = () => {
        dispatch(submitCorrectedSessions(correctedRows));
        setCorrectedRows([]);
    };

    useEffect(() => {
        fetchSessionData();
    }, []);

    let count = 0;
    useEffect(() => {
        const interval = setInterval(() => {
            // setSelectedStatus("");
            // setSelectedTicketType("");
            fetchSessionData();
            console.log('API called', count++);
        }, 60000);
        return () => {
            console.log('Interval cleared');
            clearInterval(interval);
        };
    }, [selectedStatus]);

    useEffect(() => {
        if (selectedStatus) {
            dispatch(getSessionStatusData(selectedStatus));
        }
    }, [selectedStatus]);

    useEffect(() => {
        setFilteredResults(sessionDataMapping(sessionData));
    }, [sessionData]);

    useEffect(() => {
        setFilteredResults(sessionDataMapping(sessionTriggeredData));
    }, [sessionTriggeredData]);

    // useEffect(() => {
    //     // setFilteredResults(sessionDataMapping(updatedSessionData));
    // }, [correctedRows]);

    useEffect(() => {
        setFilteredResults(sessionDataMapping(sessionStatusData));
    }, [sessionStatusData]);

    const ActionsColumn = ({ action, session }: { action: string; session: any }): JSX.Element => {
        const downloadError = () => {
            dispatch(downloadErrorFile(session.id));
        };
        return (
            <div className='action-col-main'>
                <CVATTooltip title={`Download error excel file`}>
                    {action?.toLowerCase() === 'error' && (
                        <Button
                            className='error-button'
                            onClick={downloadError}
                        >
                            <Icon component={SessionDownloadError} />
                        </Button>
                    )}
                </CVATTooltip>
            </div>
        );
    };

    const StatusComponent = ({ status }: { status: string }): JSX.Element => {
        return (
            <div className='status-col-main'>
                {status?.toLowerCase() === 'passed' && <Icon component={SessionSuccessIcon} />}
                {status?.toLowerCase() === 'in progress' && <Icon component={SessionInProgressIcon} />}
                {status?.toLowerCase() === 'corrected' && <Icon component={SessionCorrectedIcon} />}
                <div className='status-col'>{status}</div>
            </div>
        );
    };


    const handleReset = (sessionId: any) => {
        setCorrectedRows((prevRows) => prevRows.filter((row) => row.project_id !== sessionId));
    };

    const ErrorStatusComponent = ({session,onReset}:any ) => {
        const [isDropdownOpen,setIsDropdownOpen] = useState(false);
        const [dropdownStatus,setDropdownStatus] = useState<string>('ERROR');

        const handleCorrectedClick = (status: any,  session : any) => {
            if (status === 'CORRECTED') {
                setDropdownStatus(status);
                setCorrectedRows((prevRows) => [...prevRows, { project_id: session?.id, status: status }]);
            }
        };

        const handleRestore = () => {
            setDropdownStatus("ERROR");
            onReset(session.id);
            setIsDropdownOpen(false);
        };

        return (
            <Select
                className='error-select-space'
                bordered={false}
                value={dropdownStatus}
                onChange={(e) => handleCorrectedClick(e, session)}
                open={isDropdownOpen}
                style={{width:'150px'}}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '0 0 4px 0' }} />
                        <div
                            style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'end', paddingRight: '5px' }}
                        >
                            <Button type='primary' onClick={handleRestore} disabled={dropdownStatus === "ERROR"}>
                                Restore
                            </Button>
                        </div>
                    </div>
                )}
                onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
            >
                <Select.Option value='ERROR' disabled>
                    <Icon component={SessionFailedIcon} className='select-error-component' />
                    ERROR
                </Select.Option>
                <Select.Option value='CORRECTED'>
                    <Icon component={SessionCorrectedIcon} /> CORRECTED
                </Select.Option>
            </Select>
        );
      };

      const ExceptionStatusComponent = ({session,onReset}:any ) => {
        const [isDropdownOpen,setIsDropdownOpen] = useState(false);
        const [dropdownStatus,setDropdownStatus] = useState<string>('EXCEPTION');

        const handleCorrectedClick = (status: any,  session : any) => {
            if (status === 'CORRECTED') {
                setDropdownStatus(status);
                setCorrectedRows((prevRows) => [...prevRows, { project_id: session?.id, status: status }]);
            }
        };

        const handleRestore = () => {
            setDropdownStatus("EXCEPTION");
            onReset(session.id);
            setIsDropdownOpen(false);
        };

        return (
            <Select
                className='error-select-space'
                bordered={false}
                value={dropdownStatus}
                onChange={(e) => handleCorrectedClick(e, session)}
                open={isDropdownOpen}
                style={{width:'150px'}}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '0 0 4px 0' }} />
                        <div
                            style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'end', paddingRight: '5px' }}
                        >
                            <Button type='primary' onClick={handleRestore} disabled={dropdownStatus === "EXCEPTION"}>
                                Restore
                            </Button>
                        </div>
                    </div>
                )}
                onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
            >
                <Select.Option value='EXCEPTION' disabled>
                <Icon component={SessionExceptionIcon} className='select-error-component' />
                    EXCEPTION
                </Select.Option>
                <Select.Option value='CORRECTED'>
                    <Icon component={SessionCorrectedIcon} /> CORRECTED
                </Select.Option>
            </Select>
        );
      };

      const CurrentStatusColumn = ({ status, session }: { status: string; session: any }): JSX.Element => {
        if(status?.toLowerCase() === 'error')
           return <ErrorStatusComponent session={session} onReset={handleReset} />
        else if(status?.toLowerCase() === 'exception')
            return <ExceptionStatusComponent session={session} onReset={handleReset}/>
        else
            return <StatusComponent status={status} />

    };

    let SNo = 0;
    let dataSource = sessionData?.map((session: any) => {
        SNo += 1;
        return {
            SNo: SNo,
            TicketType: session?.ticket_type,
            BatchName: session?.batch,
            Recordings: session?.session_id,
            TriggerDate:
                session?.triggered_date.toLowerCase() === 'yet to trigger'
                    ? 'Yet to Trigger'
                    : session?.triggered_date.toLowerCase().split('').slice(0, 10).join(''),
            IterationCount: session?.iteration_count,
            UnitsCount: session?.units,
            CurrentStatus: <CurrentStatusColumn status={session?.status} session={session} />,
            Actions: <ActionsColumn action={session?.status} session={session} />,
        };
    });

    const sessionDataMapping = (data: any) => {
        SNo = 0;
        return data?.map((session: any) => {
            SNo += 1;
            return {
                SNo: SNo,
                TicketType: session?.ticket_type,
                BatchName: session?.batch,
                Recordings: session?.session_id,
                TriggerDate:
                    session?.triggered_date.toLowerCase() === 'yet to trigger'
                        ? 'Yet to Trigger'
                        : session?.triggered_date.toLowerCase().split('').slice(0, 10).join(''),
                IterationCount: session?.iteration_count,
                UnitsCount: session?.units,
                CurrentStatus: <CurrentStatusColumn status={session?.status} session={session} />,
                Actions: <ActionsColumn action={session?.status} session={session} />,
            };
        });
    };

    let [filteredResults, setFilteredResults] = useState(dataSource);
    let [data, setData] = useState(dataSource);

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'SNo',
            key: 'SNo',
        },
        {
            title: 'Ticket Type',
            dataIndex: 'TicketType',
            key: 'TicketType',
        },
        {
            title: 'Batch Name',
            dataIndex: 'BatchName',
            key: 'BatchName',
            sorter: (a, b) => a.BatchName.localeCompare(b.BatchName),
        },
        {
            title: 'Recordings',
            dataIndex: 'Recordings',
            key: 'Recordings',
        },
        {
            title: 'Trigger Date',
            dataIndex: 'TriggerDate',
            sorter: (a, b) => a.TriggerDate.localeCompare(b.TriggerDate),
            key: 'TriggerDate',
        },
        {
            title: 'Iteration Count',
            dataIndex: 'IterationCount',
            key: 'IterationCount',
        },
        {
            title: 'Units Count',
            dataIndex: 'UnitsCount',
            key: 'UnitsCount',
        },
        {
            title: 'Current Status',
            dataIndex: 'CurrentStatus',
            key: 'CurrentStatus',
        },
        {
            title: 'Error',
            dataIndex: 'Actions',
            key: 'Actions',
            // render: (record:{status:string} ) => (
            //     <ActionsColumn action={record?.status}
            //     />
            //   ),
        },
    ];

    const handleSearch = (searchText: string) => {
        let searchedTxt = searchText.toLowerCase();
        if (searchText !== '') {
            const filteredData: any = dataSource.filter(
                (item) =>
                    item?.TicketType?.toLowerCase().includes(searchedTxt) ||
                    item?.BatchName?.toLowerCase().includes(searchedTxt) ||
                    item?.Recordings?.toLowerCase().includes(searchedTxt) ||
                    item?.IterationCount?.toString().toLowerCase().includes(searchedTxt) ||
                    item?.UnitsCount?.toString().toLowerCase().includes(searchedTxt) ||
                    item?.CurrentStatus.props.status.toLowerCase().includes(searchedTxt) ||
                    item?.TriggerDate?.toLowerCase().includes(searchedTxt),
            );
            // setCurrentPage(1);
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(dataSource);
        }
    };

    const handleSelectTicketType = (ticketType: string) => {
        setSelectedTicketType(ticketType);
        if (ticketType) {
            const filteredData: any = dataSource.filter(
                (item) => item?.TicketType?.toLowerCase() === ticketType.toLowerCase(),
            );
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(dataSource);
        }
    };

    const handleSelectedStatus = async (statusType: string, e: any) => {
        setSelectedStatus(statusType);
        if (statusType == undefined) fetchSessionData();
    };

    let startdate: any, enddate: any;
    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        startdate = dateString;
        setStartDate(startdate);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        enddate = dateString;
        setEndDate(enddate);
    };

    const filterCard = (
        <div className='status-filter-card'>
            <div className='user-title-row'>
                <Search
                    placeholder='Search Batch Name / Recordings'
                    onSearch={handleSearch}
                    allowClear
                    enterButton
                    className='session-space'
                />
                <Select
                    // value={selectedTicketType}
                    className='session-select-space'
                    onChange={handleSelectTicketType}
                    allowClear
                    placeholder='Select Ticket Type'
                >
                    {/* <option value="" disabled selected hidden>Select Ticket Type</option> */}
                    {ticketTypes.map((ticketType: string) => (
                        <Select.Option
                            key={ticketType}
                            value={ticketType}
                        >
                            {ticketType}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    placeholder='Select Status'
                    className='session-select-space'
                    allowClear
                    onChange={handleSelectedStatus}
                >
                    {statusTypes.map((status: string) => (
                        <Select.Option key={status} value={status}>
                            {status}
                        </Select.Option>
                    ))}
                </Select>
                <DatePicker
                    disabledDate={(current) => {
                        let createdDate = moment(new Date()).utc().format();
                        let expirationDate = moment(createdDate).add(1, 'd').format('DD/MM/YYYY');
                        return current && current > moment(expirationDate, 'DD/MM/YYYY');
                    }}
                    disabled
                    defaultValue={moment(dates, dateFormat)}
                    format={'DD/MM/YYYY'}
                    placeholder={'Start Date'}
                    className='session-date'
                    onChange={onFromChange}
                />
                <DatePicker
                    disabledDate={(current) => {
                        return current && current > moment().endOf('day');
                    }}
                    disabled
                    placeholder={'End Date'}
                    className='session-date'
                    onChange={onToChange}
                    defaultValue={moment(dates, dateFormat)}
                    format={'DD/MM/YYYY'}
                />
                <Button type='primary' className='session-button-space' disabled>
                    Get Data
                </Button>
            </div>
            <div className='title-buttons'>
                <CVATTooltip title={`Fetch the latest data`}>
                    <Button type='primary' onClick={() => fetchSessionData()}>
                        Refresh
                    </Button>
                </CVATTooltip>
                <CVATTooltip title={`Trigger the corrected sessions`}>
                    <Button
                        type='primary'
                        className='session-button-space'
                        disabled={correctedRows.length === 0}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                </CVATTooltip>
            </div>
        </div>
    );

    return (
        <>
            {filterCard}
            {/* <Table columns={columns} dataSource={filteredResults} /> */}
            <Table
                className='table-layout'
                columns={columns}
                dataSource={filteredResults.length > 0 ? filteredResults : data}
            />
        </>
    );
};

export default React.memo(SessionTable);
