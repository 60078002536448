// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
export enum ReportSagaActionTypes {
    GET_PROJECTS_REPORTS = 'GET_PROJECTS_REPORTS',
    GET_PROJECTS_REPORTS_SUCCESS = ' GET_PROJECTS_REPORTS_SUCCESS',
    GET_PROJECTS_REPORTS_FAILED = 'GET_PROJECTS_REPORTS_FAILED',
    GET_PROJECTS_NAME = 'GET_PROJECTS_NAME',
    GET_PROJECTS_NAME_SUCCESS = 'GET_PROJECTS_NAME_SUCCESS',
    GET_PROJECTS_NAME_FAILED = 'GET_PROJECTS_NAME_FAILED',
    GET_USER_WISE_REPORT = 'GET_USER_WISE_REPORT',
    GET_USER_WISE_REPORT_SUCCESS = 'GET_USER_WISE_REPORT_SUCCESS',
    GET_USER_WISE_REPORT_FAILED = 'GET_USER_WISE_REPORT_FAILED',
    GET_DAY_WISE_REPORT = 'GET_DAY_WISE_REPORT',
    GET_DAY_WISE_REPORT_SUCCESS = 'GET_DAY_WISE_REPORT_SUCCESS',
    GET_DAY_WISE_REPORT_FAILED = 'GET_DAY_WISE_REPORT_FAILED',
    GET_PROJECT_APP_STAGES = 'GET_PROJECT_APP_STAGES',
    GET_PROJECT_APP_STAGES_SUCCESS = 'GET_PROJECT_APP_STAGES_SUCCESS',
    GET_PROJECT_APP_STAGES_FAILED = 'GET_PROJECT_APP_STAGES_FAILED',
    GET_PROJECT_APP_STAGES_CUSTOMER = 'GET_PROJECT_APP_STAGES_CUSTOMER',
    GET_PROJECT_APP_STAGES_CUSTOMER_SUCCESS = 'GET_PROJECT_APP_STAGES_CUSTOMER_SUCCESS',
    GET_PROJECT_APP_STAGES_CUSTOMER_FAILED = 'GET_PROJECT_APP_STAGES_CUSTOMER_FAILED',
    GET_PROJECT_REPORTS_DOWNLOAD = 'GET_PROJECT_REPORTS_DOWNLOAD',
    GET_PROJECT_REPORTS_DOWNLOAD_SUCCESS = 'GET_PROJECT_REPORTS_DOWNLOAD_SUCCESS',
    GET_PROJECT_REPORTS_DOWNLOAD_FAILED = 'GET_PROJECT_REPORTS_DOWNLOAD_FAILED',
    GET_STAGE_REPORT = 'GET_STAGE_REPORT',
    GET_STAGE_REPORT_SUCCESS = 'GET_STAGE_REPORT_SUCCESS',
    GET_STAGE_REPORT_FAILED = 'GET_STAGE_REPORT_FAILED',
    GET_APP_STAGE_REPORT = 'GET_APP_STAGE_REPORT',
    GET_APP_STAGE_REPORT_SUCCESS = 'GET_APP_STAGE_REPORT_SUCCESS',
    GET_APP_STAGE_REPORT_FAILED = 'GET_APP_STAGE_REPORT_FAILED',
    GET_VIEW_TASK = 'GET_VIEW_TASK',
    GET_VIEW_TASK_SUCCESS = 'GET_VIEW_TASK_SUCCESS',
    GET_VIEW_TASK_FAILED = 'GET_VIEW_TASK_FAILED',
    RELEASE_PROJECT_APP_STAGES = 'RELEASE_PROJECT_APP_STAGES',
    RELEASE_PROJECT_APP_STAGES_SUCCESS = 'RELEASE_PROJECT_APP_STAGES_SUCCESS',
    RELEASE_PROJECT_APP_STAGES_FAILED = 'RELEASE_PROJECT_APP_STAGES_FAILED',
    RESET_REPORTS_STATE = 'RESET_REPORTS_STATE',
    RELEASE_CURRENT_USER_STAGES = 'RELEASE_CURRENT_USER_STAGES',
    RELEASE_CURRENT_USER_STAGES_SUCCESS = 'RELEASE_CURRENT_USER_STAGES_SUCCESS',
    RELEASE_CURRENT_USER_STAGES_FAILED = 'RELEASE_CURRENT_USER_STAGES_FAILED',
    RELEASE_DIFFERENT_USER_STAGES = 'RELEASE_DIFFERENT_USER_STAGES',
    RELEASE_DIFFERENT_USER_STAGES_SUCCESS = 'RELEASE_DIFFERENT_USER_STAGES_SUCCESS',
    RELEASE_DIFFERENT_USER_STAGES_FAILED = 'RELEASE_DIFFERENT_USER_STAGES_FAILED',
    GET_WORKFLOW_HISTORY = 'GET_WORKFLOW_HISTORY',
    GET_WORKFLOW_HISTORY_SUCCESS = 'GET_WORKFLOW_HISTORY_SUCCESS',
    GET_WORKFLOW_HISTORY_FAILED = 'GET_WORKFLOW_HISTORY_FAILED',
    RESET_ANNOTATION_INFO = 'RESET_ANNOTATION_INFO',
    GET_PROJECT_STAGE_DATA_STATUS = 'GET_PROJECT_STAGE_DATA_STATUS',
    GET_PROJECT_STAGE_DATA_STATUS_SUCCESS = 'GET_PROJECT_STAGE_DATA_STATUS_SUCCESS',
    GET_PROJECT_STAGE_DATA_STATUS_FAILED = 'GET_PROJECT_STAGE_DATA_STATUS_FAILED',
    CHANGE_IFRAME_VALUE_CUSTOMER = ' CHANGE_IFRAME_VALUE_CUSTOMER',
    GET_USER_WISE_TIME_REPORT = 'GET_USER_WISE_TIME_REPORT',
    GET_USER_WISE_TIME_REPORT_SUCCESS = 'GET_USER_WISE_TIME_REPORT_SUCCESS',
    GET_USER_WISE_TIME_REPORT_FAILED = 'GET_USER_WISE_TIME_REPORT_FAILED',
    GET_USERS_TOTAL_TIME_REPORT = 'GET_USERS_TOTAL_TIME_REPORT',
    GET_USERS_TOTAL_TIME_REPORT_SUCCESS = 'GET_USERS_TOTAL_TIME_REPORT_SUCCESS',
    GET_USERS_TOTAL_TIME_REPORT_FAILED = 'GET_USERS_TOTAL_TIME_REPORT_FAILED',

}

export function getProjectReportStatus() {
    return {
        type: ReportSagaActionTypes.GET_PROJECTS_REPORTS,
    };
}
//raju
export function getUserWiseTimeReport(start:any, userid:any):any {
    return {
        type: ReportSagaActionTypes.GET_USER_WISE_TIME_REPORT,
        payload: {start,userid}
    };
}
export function getUsersTotalTime(startdate:any, endadate:any):any {
    return {
        type: ReportSagaActionTypes.GET_USERS_TOTAL_TIME_REPORT,
        payload: {startdate,endadate}
    };
}

export function activeIframeCustomer() {
    return {
        type: ReportSagaActionTypes.CHANGE_IFRAME_VALUE_CUSTOMER,
    };
}
export function getProjectsName() {
    return {
        type: ReportSagaActionTypes.GET_PROJECTS_NAME,
    };
}
export function getUserWiseReport() {
    return {
        type: ReportSagaActionTypes.GET_USER_WISE_REPORT,
    };
}

export function getDayWiseReport(start, end) {
    return {
        type: ReportSagaActionTypes.GET_DAY_WISE_REPORT,
        payload: {start, end}
    };
}

export function fetchProjectStages(projectId: any) {
    return {
        type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES,
        payload: projectId,
    };
}

export function fetchProjectStageData(projectId: any) {
    return {
        type: ReportSagaActionTypes.GET_PROJECT_STAGE_DATA_STATUS,
        payload: projectId,
    };
}

export function fetchProjectStagesCustomer(projectId: any) {
    return {
        type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES_CUSTOMER,
        payload: projectId,
    };
}

export function getDownloadProjectReports(projectId: any) {
    return {
        type: ReportSagaActionTypes.GET_PROJECT_REPORTS_DOWNLOAD,
        payload: projectId,
    };
}

export function fetchStageReport(projectAppId: any) {
    return {
        type: ReportSagaActionTypes.GET_STAGE_REPORT,
        payload: projectAppId,
    };
}

export function fetchappStageReport(projectAppId: any) {
    return {
        type: ReportSagaActionTypes.GET_APP_STAGE_REPORT,
        payload: projectAppId,
    };
}

export function fetchviewTask(workFlowId: any) {
    return {
        type: ReportSagaActionTypes.GET_VIEW_TASK,
        payload: workFlowId,
    };
}

export function resetAnnotationInfo() {
    return {
        type: ReportSagaActionTypes.RESET_ANNOTATION_INFO,
    };
}

export function reassignProjectStages(workflow_id: any, current_stage_id: any, user_id: any, username: any, different_stage_id: any) {
    return {
        type: ReportSagaActionTypes.RELEASE_PROJECT_APP_STAGES,
        payload: { workflow_id, current_stage_id, user_id, username, different_stage_id }
    };
}

export function fetchCurrentAssignedUser(payload: any) {
    return {
        type: ReportSagaActionTypes.RELEASE_CURRENT_USER_STAGES,
        payload,
    };
}

export function fetchDifferentAssignedUser(payload: any) {
    return {
        type: ReportSagaActionTypes.RELEASE_DIFFERENT_USER_STAGES,
        payload,
    };
}

export function fetchWorkflowHistory(workflow_id: any) {
    return {
        type: ReportSagaActionTypes.GET_WORKFLOW_HISTORY,
        workflow_id,
    };
}

export function resetReportState() {
    return {
        type: ReportSagaActionTypes.RESET_REPORTS_STATE,
    };
}


