import "./overall-status-styles.scss";
import React from 'react';
import { Tabs, Typography } from 'antd';
const { TabPane } = Tabs;
const { Text } = Typography;
import SessionMainPage from "./session-main-page"
import SessionTable from "./session-table";

function OverallStatusComponent(): JSX.Element{
  return (
    <div className='status-main-page'>
            <Text className='status-title'>Overall Status</Text>
            <Tabs defaultActiveKey='SessionWise' className='tab-report'>
                <TabPane tab='Session Wise' key='SessionWise' className='tab-pane-holder-report'>
                    {/* <SessionMainPage /> */}
                    <SessionTable />
                </TabPane>
                <TabPane tab='Analytics' key='Analytics' className='tab-pane-holder-report'>
                    Analytics
                </TabPane>
            </Tabs>
        </div>
  )
}

export default OverallStatusComponent