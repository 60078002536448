// Copyright (C) 2023 LTTS
//
// SPDX-License-Identifier: MIT

import { BoundariesActionTypes } from '../actions/boundaries-actions';
import { OverallStatusSagaActionTypes } from '../actions/overall-status-actions';
import { ExportAutomation } from './interfaces';

const initialState: ExportAutomation = {
    sessionData: [],
    statusData: [],
    ticketData: [],
    triggeredData: []
};

export default function (state = initialState, action: any): ExportAutomation {
    switch (action.type) {
        case OverallStatusSagaActionTypes.GET_SESSION_WISE_DATA_SUCCESS:
            return {
                ...state,
                sessionData: action.response,
            };
        case OverallStatusSagaActionTypes.GET_SESSION_STATUS_DATA_SUCCESS:
            return {
                ...state,
                statusData: action.response,
            };
        case OverallStatusSagaActionTypes.GET_SESSION_TICKET_DATA_SUCCESS:
            return {
                ...state,
                ticketData: action.response,
            };
        case OverallStatusSagaActionTypes.SUBMIT_CORRECTED_SESSIONS_SUCCESS:
            return {
                ...state,
                triggeredData: action.response,
            };
        case BoundariesActionTypes.RESET_AFTER_ERROR: {
            return { ...initialState };
        }
        default:
            return state;
    }
}
