import { call, all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ReportSagaActionTypes } from '../actions/reports-action';

const Axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

async function ProjectStatusReports() {
    const response = await Axios.get(`${API_URL}/api/projects/data/status`);
    return response.data;
}

function* projectGetStatusReports() {
    try {
        const response = yield call(ProjectStatusReports);
        yield put({ type: ReportSagaActionTypes.GET_PROJECTS_REPORTS_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECTS_REPORTS_FAILED, message: e });
    }
}

async function getProjectsName() {
    const response = await Axios.get(`${API_URL}/api/projects/name`);
    return response.data;
}

function* reportProjectsName(): any {
    try {
        const response = yield call(getProjectsName);
        yield put({ type: ReportSagaActionTypes.GET_PROJECTS_NAME_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECTS_NAME_FAILED, message: e });
    }
}
async function getUserReport() {
    const response = await Axios.get(`${API_URL}/api/reports/userwise/annotations`);
    return response.data;
}

function* getUserWiseReport(): any {
    try {
        const response = yield call(getUserReport);
        yield put({ type: ReportSagaActionTypes.GET_USER_WISE_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_USER_WISE_REPORT_FAILED, message: e });
    }
}

async function getDayReport(start, end) {
    let response = null;
    if(start && end){
        response = await Axios.get(`${API_URL}/api/reports/daywise?from=${start}&to=${end}`);
    } else if (start) {
        response = await Axios.get(`${API_URL}/api/reports/daywise?from=${start}`);
    } else if (end) {
        response = await Axios.get(`${API_URL}/api/reports/daywise?to=${end}`);
    }
    if(response.data) {
        return response.data;
    }
    return response;
}
async function getUsertimeReport(start,  userid) {
    let response = null;
    if(start && userid){
        response = await Axios.get(`${API_URL}/api/timetaken/user?date=${start}&user_id=${userid}`);
    }
    // else if (start) {
    //     response = await Axios.get(`${API_URL}/api/timetaken/between?start=${start}&user_id=${userid}`);
    // } else if (end) {
    //     response = await Axios.get(`${API_URL}/api/timetaken/between?stop=${end}&user_id=${userid}`);
    // }
    if(response.data) {
        return response.data;
    }
    return response;
}
function* getDayWiseReport(data): any {
    const start = data.payload.start;
    const end = data.payload.end;

    try {
        const response = yield call(getDayReport, start, end);
        yield put({ type: ReportSagaActionTypes.GET_DAY_WISE_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_DAY_WISE_REPORT_FAILED, message: e });
    }
}
//raju added
async function getUserTotalTimeReport(startdate, enddate) {
    const response = await Axios.get(`${API_URL}/api/timetaken/total?stop_date=${enddate}&start_date=${startdate}`);
    return response.data;
}
function* getUsersTotaltime(data: any): any {
    const startdate = data.payload.startdate;
    const enddate = data.payload.endadate;
    try {
        const response = yield call(getUserTotalTimeReport,startdate,enddate);
        yield put({ type: ReportSagaActionTypes.GET_USERS_TOTAL_TIME_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_USERS_TOTAL_TIME_REPORT_FAILED, message: e });
    }
}
function* getUserWiseTimeReport(data: any): any {
    const start = data.payload.start;
    const userid = data.payload.userid;

    try {
        const response = yield call(getUsertimeReport, start, userid);
        yield put({ type: ReportSagaActionTypes.GET_USER_WISE_TIME_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_USER_WISE_TIME_REPORT_FAILED, message: e });
    }
}
async function getProjectId(id: any) {
    const projectId = id.payload
    const response = await Axios.get(`${API_URL}/api/reports/project/wf/status?project_id=${projectId}`);
    return response.data;
}

function* fetchProjectAppStages(projectId: any): any {
    try {
        const response = yield call(getProjectId, projectId);
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES_FAILED, message: e });
    }
}

async function getProjectDataId(id: any) {
    const projectId = id.payload
    const response = await Axios.get(`${API_URL}/api/reports/stage/data/status?project_id=${projectId}`);
    return response.data;
}

function* fetchProjectAppDataStages(projectId: any): any {
    try {
        const response = yield call(getProjectDataId, projectId);
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_STAGE_DATA_STATUS_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_STAGE_DATA_STATUS_FAILED, message: e });
    }
}

async function getProjectIdCustomer(id: any) {
    const projectId = id.payload
    const response = await Axios.get(`${API_URL}/api/reports/stage/data/status?project_id=${projectId}`);
    return response.data;
}

function* fetchProjectAppStagesCustomer(projectId: any): any {
    try {
        const response = yield call(getProjectIdCustomer, projectId);
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES_CUSTOMER_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_APP_STAGES_CUSTOMER_FAILED, message: e });
    }
}

async function getDownloadProjectId(id: any) {
    const projectId = id.payload
    const response = await Axios.get(`${API_URL}/api/projects/annotation_count?project_id=${projectId}`);
    return response.data;
}

function* fetchProjectAppDownload(projectId: any): any {
    try {
        const response = yield call(getDownloadProjectId, projectId);
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_REPORTS_DOWNLOAD_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_PROJECT_REPORTS_DOWNLOAD_FAILED, message: e });
    }
}

async function getProjectAppId(id: any) {
    const appId = id.payload
    const response = await Axios.get(`${API_URL}/api/reports/project_app/annotation_count?project_app_id=${appId}`);
    return response.data;
}

function* fetchAppStagesReports(projectAppId: any): any {
    try {
        const response = yield call(getProjectAppId, projectAppId);
        yield put({ type: ReportSagaActionTypes.GET_STAGE_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_STAGE_REPORT_FAILED, message: e });
    }
}

async function getProjectwiseAppId(id: any) {
    const appId = id.payload
    const response = await Axios.get(`${API_URL}/api/customer/review?project_app_id=${appId}`);
    return response.data;
}

function* fetchAppStageswiseReports(projectAppId: any): any {
    try {
        const response = yield call(getProjectwiseAppId, projectAppId);
        yield put({ type: ReportSagaActionTypes.GET_APP_STAGE_REPORT_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_APP_STAGE_REPORT_FAILED, message: e });
    }
}

async function getviewTaskDetails(id: any) {
    const appId = id.payload
    const response = await Axios.get(`${API_URL}/api/customer/view/task?workflow_id=${appId}`);
    return response.data;
}

function* fetchviewTaskwiseDetails(projectAppId: any): any {
    try {
        const response = yield call(getviewTaskDetails, projectAppId);
        yield put({ type: ReportSagaActionTypes.GET_VIEW_TASK_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_VIEW_TASK_FAILED, message: e });
    }
}

async function getStageValues(stageValues: any) {
    const workflowId = stageValues?.payload?.workflow_id;
    const response = await Axios.patch(`${API_URL}/api/wfmapping/${workflowId}/reassign`, stageValues?.payload);
    return response;
}

function* releaseProjectStages(stageValues: any): any {
    const workflowId = stageValues?.payload?.workflow_id;
    try {
        const response = yield call(getStageValues, stageValues);
        yield put({ type: ReportSagaActionTypes.RELEASE_PROJECT_APP_STAGES_SUCCESS, response, workflowId });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.RELEASE_PROJECT_APP_STAGES_FAILED, message: e });
    }
}

async function getCurrentUserValues(stageValues: any) {
    const projectAppId = stageValues?.payload;
    const response = await Axios.get(`${API_URL}/api/project_app/${projectAppId}/assigned_user`);
    return response.data;
}

function* releaseCurrentUser(stageValues: any): any {
    try {
        const response = yield call(getCurrentUserValues, stageValues);
        yield put({ type: ReportSagaActionTypes.RELEASE_CURRENT_USER_STAGES_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.RELEASE_CURRENT_USER_STAGES_FAILED, message: e });
    }
}

async function getDifferentUserValues(stageValues: any) {
    const projectAppId = stageValues?.payload;
    const response = await Axios.get(`${API_URL}/api/project_app/users?project_id=${projectAppId}`);
    return response.data;
}

function* releaseDifferentUser(stageValues: any): any {
    try {
        const response = yield call(getDifferentUserValues, stageValues);
        yield put({ type: ReportSagaActionTypes.RELEASE_DIFFERENT_USER_STAGES_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.RELEASE_DIFFERENT_USER_STAGES_FAILED, message: e });
    }
}

async function getworkflowHistory(stageValues: any) {
    const workflowId = stageValues?.workflow_id;
    const response = await Axios.get(`${API_URL}/api/workflow/${workflowId}/history`);
    return response.data;
}

function* fetchStagesWorkflowHistory(stageValues: any): any {
    try {
        const response = yield call(getworkflowHistory, stageValues);
        yield put({ type: ReportSagaActionTypes.GET_WORKFLOW_HISTORY_SUCCESS, response });
    } catch (e) {
        yield put({ type: ReportSagaActionTypes.GET_WORKFLOW_HISTORY_FAILED, message: e });
    }
}

export function* reportsWatcher() {
    yield all([
        takeEvery(ReportSagaActionTypes.GET_PROJECTS_REPORTS, projectGetStatusReports),
        takeEvery(ReportSagaActionTypes.GET_PROJECTS_NAME, reportProjectsName),
        takeEvery(ReportSagaActionTypes.GET_USER_WISE_REPORT, getUserWiseReport),
        takeEvery(ReportSagaActionTypes.GET_PROJECT_APP_STAGES, fetchProjectAppStages),
        takeEvery(ReportSagaActionTypes.RELEASE_PROJECT_APP_STAGES, releaseProjectStages),
        takeEvery(ReportSagaActionTypes.RELEASE_CURRENT_USER_STAGES, releaseCurrentUser),
        takeEvery(ReportSagaActionTypes.RELEASE_DIFFERENT_USER_STAGES, releaseDifferentUser),
        takeEvery(ReportSagaActionTypes.GET_WORKFLOW_HISTORY, fetchStagesWorkflowHistory),
        takeEvery(ReportSagaActionTypes.GET_STAGE_REPORT, fetchAppStagesReports),
        takeEvery(ReportSagaActionTypes.GET_APP_STAGE_REPORT, fetchAppStageswiseReports),
        takeLatest(ReportSagaActionTypes.GET_VIEW_TASK, fetchviewTaskwiseDetails),
        takeEvery(ReportSagaActionTypes.GET_PROJECT_REPORTS_DOWNLOAD, fetchProjectAppDownload),
        takeEvery(ReportSagaActionTypes.GET_PROJECT_STAGE_DATA_STATUS, fetchProjectAppDataStages),
        takeEvery(ReportSagaActionTypes.GET_PROJECT_APP_STAGES_CUSTOMER, fetchProjectAppStagesCustomer),
        takeEvery(ReportSagaActionTypes.GET_DAY_WISE_REPORT, getDayWiseReport),
        takeEvery(ReportSagaActionTypes.GET_USER_WISE_TIME_REPORT, getUserWiseTimeReport),
        takeEvery(ReportSagaActionTypes.GET_USERS_TOTAL_TIME_REPORT, getUsersTotaltime),
    ]);
}
