
export enum AppsNStagesActionsTypes {
    CREATE_STAGES = 'CREATE_STAGES',
    CREATE_STAGES_SUCCESS = 'CREATE_STAGES_SUCCESS',
    CREATE_STAGES_FAILED = 'CREATE_STAGES_FAILED',

    GET_APPS_REQUESTED = 'GET_APPS_REQUESTED',
    GET_APPS_SUCCESS = 'GET_APPS_SUCCESS',
    GET_APPS_FAILED = 'GET_APPS_FAILED',

    GET_USERS_REQUESTED = 'GET_USERS_REQUESTED',
    GET_ADMINS_REQUESTED = 'GET_ADMINS_REQUESTED',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
    GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS',

    SAVE_ADMINS = 'SAVE_ADMINS',
    SAVE_ADMINS_SUCCESS = 'SAVE_ADMINS_SUCCESS',
    SAVE_ADMINS_FAILED = 'SAVE_ADMINS_FAILED',
    GET_USERS_FAILED = 'GET_USERS_FAILED',
    GET_ADMINS_FAILED = 'GET_ADMINS_FAILED',

    MAP_USERS_TO_APP = 'MAP_USERS_TO_APP',
    MAPPING_USERSTOAPP_SUCESS = 'MAPPING_USERSTOAPP_SUCESS',
    MAPPING_USERSTOAPP_FAILED = 'MAPPING_USERSTOAPP_FAILED',

    FETCH_PROJECT_APPS = 'FETCH_PROJECT_APPS',
    FETCH_PROJECT_APPS_SUCCESS = 'FETCH_PROJECT_APPS_SUCCESS',
    FETCH_PROJECT_APPS_FAILED = 'FETCH_PROJECT_APPS_FAILED',

    FETCH_WORKFLOW_MAPPING = 'FETCH_WORKFLOW_MAPPING',
    FETCH_WORKFLOW_MAPPING_SUCCESS = 'FETCH_WORKFLOW_MAPPING_SUCCESS',
    FETCH_WORKFLOW_MAPPING_FAILED = 'FETCH_WORKFLOW_MAPPING_FAILED',

    SUBMIT_CLOSE = 'SUBMIT_CLOSE',
    SUBMIT_NEXT = 'SUBMIT_NEXT',

    FETCH_PREVIOUS_STAGES = 'FETCH_PREVIOUS_STAGES',
    FETCH_PREVIOUS_STAGES_SUCCESS = 'FETCH_PREVIOUS_STAGES_SUCCESS',
    FETCH_PREVIOUS_STAGES_FAILED = 'FETCH_PREVIOUS_STAGES_FAILED',

    UPDATE_PROJECTAPP_DATA = 'UPDATE_PROJECTAPP_DATA',
    UPDATE_PROJECTAPP_DATA_SUCCESS = 'UPDATE_PROJECTAPP_DATA_SUCCESS',
    UPDATE_PROJECTAPP_DATA_FAILED = 'UPDATE_PROJECTAPP_DATA_FAILED',

    SAVE_WORKFLOW = 'SAVE_WORKFLOW',
    SAVE_WORKFLOW_SUCCESS = 'SAVE_WORKFLOW_SUCCESS',
    SAVE_WORKFLOW_FAILED = 'SAVE_WORKFLOW_FAILED',

    CHANGE_IFRAME_VALUE = 'CHANGE_IFRAME_VALUE',

    FETCH_ASSIGNED_PROJECT_APPS = 'FETCH_ASSIGNED_PROJECT_APPS',
    FETCH_ASSIGNED_PROJECT_APPS_SUCCESS = 'FETCH_ASSIGNED_PROJECT_APPS_SUCCESS',
    FETCH_ASSIGNED_PROJECT_APPS_FAILED = 'FETCH_ASSIGNED_PROJECT_APPS_FAILED',

    PROJECT_APPS_USER_ASSIGNED = 'PROJECT_APPS_USER_ASSIGNED',
    PROJECT_APPS_USER_ASSIGNED_SUCCESS = 'PROJECT_APPS_USER_ASSIGNED_SUCCESS',
    PROJECT_APPS_USER_ASSIGNED_FAILED = 'PROJECT_APPS_USER_ASSIGNED_FAILED',

    RESET_APPS_STATE = 'RESET_APPS_STATE',

    SWITCH_REJECT_DIALOG = 'SWITCH_REJECT_DIALOG',

    CREATE_REJECT_STAGES = 'CREATE_REJECT_STAGES',
    CREATE_REJECT_STAGES_SUCCESS = 'CREATE_REJECT_STAGES_SUCCESS',
    CREATE_REJECT_STAGES_FAILED = 'CREATE_REJECT_STAGES_FAILED',

    FETCH_ANNOTATOR_TIME = 'FETCH_ANNOTATOR_TIME',
    FETCH_ANNOTATOR_TIME_SUCCESS = 'FETCH_ANNOTATOR_TIME_SUCCESS',
    FETCH_ANNOTATOR_TIME_FAILED = 'FETCH_ANNOTATOR_TIME_FAILED',

    FETCH_TICKET_REPORTS = 'FETCH_TICKET_REPORTS',
    FETCH_TICKET_REPORTS_SUCCESS = 'FETCH_TICKET_REPORTS_SUCCESS',
    FETCH_TICKET_REPORTS_FAILED = 'FETCH_TICKET_REPORTS_FAILED',

    RESET_TICKETS_REPORTS_STATE = 'RESET_TICKETS_REPORTS_STATE',
    SEND_ANNOTATOR_TIME = 'SEND_ANNOTATOR_TIME',
    SEND_ANNOTATOR_TIME_SUCCESS = 'SEND_ANNOTATOR_TIME_SUCCESS',
    SEND_ANNOTATOR_TIME_FAILED = 'SEND_ANNOTATOR_TIME_FAILED',
    USER_ACTIVITY_TIME = 'USER_ACTIVITY_TIME',
    USER_ACTIVITY_TIME_SUCCESS = 'USER_ACTIVITY_TIME_SUCCESS',
    USER_ACTIVITY_TIME_FAILED = 'USER_ACTIVITY_TIME_FAILED',
}

export function resetState() {
    return {
        type: AppsNStagesActionsTypes.RESET_APPS_STATE,
    };
}

export function createStages(nodeData: any) {
    return {
        type: AppsNStagesActionsTypes.CREATE_STAGES,
        payload: { nodeData }
    };
}

export function getApps(payload: {}) {
    return {
        type: AppsNStagesActionsTypes.GET_APPS_REQUESTED,
        payload
    };
}

export function fetchUsers() {
    return {
        type: AppsNStagesActionsTypes.GET_USERS_REQUESTED,
    };
}

export function fetchAdmins(id) {
    return {
        type: AppsNStagesActionsTypes.GET_ADMINS_REQUESTED,
        payload: id
    };
}

export function assignAppToUsers(payload: any) {
    return {
        type: AppsNStagesActionsTypes.MAP_USERS_TO_APP,
        payload
    };
}

export function fetchProjectApps() {
    return {
        type: AppsNStagesActionsTypes.FETCH_PROJECT_APPS,
    }
}

export function activateIframe(payload) {
    return {
        type: AppsNStagesActionsTypes.CHANGE_IFRAME_VALUE,
        payload
    }
}

export function fetchWorkflowMapping(payload: any) {
    return {
        type: AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING,
        payload
    }
}

export function submitClose() {
    return {
        type: AppsNStagesActionsTypes.SUBMIT_CLOSE,
    }
}

export function submitNext() {
    return {
        type: AppsNStagesActionsTypes.SUBMIT_NEXT,
    }
}

export function updateProjectAppName(payload) {
    return {
        type: AppsNStagesActionsTypes.UPDATE_PROJECTAPP_DATA,
        payload
    }
}

export function saveWorkflowId(payload) {
    return {
        type: AppsNStagesActionsTypes.SAVE_WORKFLOW,
        payload
    }
}
export function saveAdminUsers(assignedUser: any, redirect: any) {
    return {
        type: AppsNStagesActionsTypes.SAVE_ADMINS,
        payload: { projectAdmin: assignedUser, redirectPage: redirect },
    }
}

export function fetchAnnotatorTime(payload: any) {
    return {
        type: AppsNStagesActionsTypes.FETCH_ANNOTATOR_TIME,
        payload,
    }
}
// code added by raju
export function SendAnnotatorTime(payload: any) {
    return {
        type: AppsNStagesActionsTypes.SEND_ANNOTATOR_TIME,
        payload,
    }
}
export function Useractivity(payload: any) {
    return {
        type: AppsNStagesActionsTypes.USER_ACTIVITY_TIME,
        payload,
    }
}

export function fetchTicketReports(ticketId: any) {
    return {
        type: AppsNStagesActionsTypes.FETCH_TICKET_REPORTS,
        ticketId,
    }
}

export function resetReportState() {
    return {
        type: AppsNStagesActionsTypes.RESET_TICKETS_REPORTS_STATE,
    };
}

export function getProjectApps({ projectId }) {
    return {
        type: AppsNStagesActionsTypes.FETCH_ASSIGNED_PROJECT_APPS,
        projectId,
    }
}
export function dispatchPreviousStage(workflowId: any) {
    return {
        type: AppsNStagesActionsTypes.FETCH_PREVIOUS_STAGES,
        workflowId,
    }
}

export function switchRejectDialog(show?: boolean) {
    return {
        type: AppsNStagesActionsTypes.SWITCH_REJECT_DIALOG,
        payload: {
            show,
        },
    };
}

export function dispatchRejectStages(data: any) {
    return {
        type: AppsNStagesActionsTypes.CREATE_REJECT_STAGES,
        data,
    };
}

export function getProjectAppAssignedUsers(node) {
    const { id } = node;
    return {
        type: AppsNStagesActionsTypes.PROJECT_APPS_USER_ASSIGNED,
        id,
    }
}

